import React, { createContext, useState, FC, useEffect } from 'react'
import { IUser, IUserOccasion } from '../types/types-internal';
import avatarRed from '../assets/icons/avatar-red.svg'
import { SubscriptionType } from '../enums/UserEnums';

type UserContextState = {
  user: IUser,
  updateUser: (user: IUser) => void
}

const userDefaultValues: UserContextState = {
  user: {
    firstName: '',
    lastName: '',
    ageRange: '',
    birthday: undefined,
    city: '',
    country: '',
    email: '',
    gender: '',
    imagePathUrl: '',
    profilePhotoDownloadUrl: avatarRed,
    isLoggedIn: false,
    userId: '',
    state: '',
    userName: '',
    subscription: SubscriptionType.Undefined,
  },
  updateUser: () => { }
}

export const UserContext = createContext<UserContextState>(userDefaultValues);

const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IUser>(userDefaultValues.user);
  const updateUser = (newUser: IUser) => setUser({ ...newUser });

  //Pull the profile image first thing the site loads. 
  useEffect(() => {
    (async () => {
      if (user.imagePathUrl) {
        let imageResp = await fetch('api/Images/GetImage?url=' + user.imagePathUrl);
        updateUser({ ...user, profilePhotoDownloadUrl: imageResp.url });
      }
    })();
  }, [user.imagePathUrl]);

  return (
    <UserContext.Provider
      value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;