import { FunctionComponent, useState } from "react";
import { Col, Row } from 'react-bootstrap'

type RelationshipComponentProps= {
    relationship?: any,
    setRelationship:any
}

const RelationshipFormComponent = (props: RelationshipComponentProps) => {

    const relations = [
        "Spouse",
        "Significant Other",
        "Child",
        "Parent",
        "Sibling",
        "Extended Family",
        "Grandparent",
        "Grandchild",
        "Best Friend",
        "Friend",
        "Manager / Supervisor",
        "Colleague",
        "Other",
    ]

    return (
        <Row > 
            <h5>Relationship to</h5>
            <Col style={{ width: "100%" }} sm={12} md={4}>
                <select  className="DatePicker" placeholder="Relationship" onChange={(e) => props.setRelationship(e.currentTarget.value)}>
                    <option hidden value=""> { props.relationship || "-- Select --" } </option>
                    {relations.map((option) => (
                        <option value={option}>{option}</option>
                    ))}
                </select>
            </Col>
        </Row>
    );

}

export { RelationshipFormComponent };