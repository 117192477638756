import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import './HeaderGroup.scss'
import { Col, Row } from 'react-bootstrap'
import BaseButton from './BaseButton'
import gift from '../../assets/icons/giftbox2.svg'

type HeaderGroupProps = {
    heading: string,
    buttonTitle: string,
    buttonIcon: string,
    buttonAlt: string,
    onClick?: any,
    showLikedButton?: boolean
}

const HeaderGroup: FunctionComponent<HeaderGroupProps> = ({ heading, buttonTitle, buttonIcon, buttonAlt, onClick, showLikedButton }) => {

    const history = useHistory()

    function goToLikedProductsPage() {
        var url = new URL(window.location.toString())
        var connectionId = "-1";

        console.log("URL", url)
        if (window.location.toString().includes("connection-profile")) {
            connectionId = url.searchParams.get("id")!
        }

        history.push("/liked-products?connectionId=" + connectionId)
    }

    return (
        <Row className="header-targer-container">
            <Col xs={9} md={6} lg={8} className="header-heading">
                <h5>{heading}</h5>
                {showLikedButton
                    && <BaseButton
                        className="liked-products"
                        icon={gift}
                        title="Show liked products"
                        onClick={() => goToLikedProductsPage()}
                    />}

            </Col>
            <Col xs={3} md={6} lg={4} className="recommendations-button">
                <BaseButton
                    className="recommendations"
                    onClick={() => onClick()}
                    title={buttonTitle}
                    icon={buttonIcon}
                    alt={buttonAlt} />
            </Col>
        </Row>
    )
}

export default HeaderGroup;
