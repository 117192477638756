import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Dropdown  } from 'react-bootstrap';
import BaseButton from '../../base-components/BaseButton';
import cancelIcon from '../../../assets/icons/cancel-white.svg';
import rightArrow from '../../../assets/icons/right-arrow.svg';
import './WelcomeBackModal.scss';
import { IUser } from "../../../types/types-internal";

interface Props {
    show: boolean,
    onHide: any,
    title: string,
    onPositive?: any,
    buttonText?: string,
    subtitle?: string,
    img?: string,
    user?: IUser,
    showSpinner?: boolean,
}

function WelcomeBackPopUp(props: Props) {

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="welcome-back-modal pt-5">
                <Modal.Title className="pt-5" id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="welcome-back-modal">
                {props.subtitle && 
                    <p className="subtitle pb-3"> {props.subtitle} </p>
                }
                <Row className="p-2">
                    <img src={props.img} />
                    <Row>
                        <BaseButton
                            className="mt-5 mb-4"
                            onClick={() => props.onHide()}
                            title={props.buttonText || "Continue"}
                            icon={rightArrow}
                            alt="Cancel" 
                        />  
                    </Row> 
                </Row>  
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    buttonTitle?: string,
    modalTitle:string,
    icon: string,
    onPositive?: any,
    buttonText?: string,
    subtitle?: string,
    img?: string,
    user?: IUser,
    showSpinner?: boolean,
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        if(props.user!.reactivated)
        {
            setModalShow(true)
        }
    }, [])

    function onClick()
    { 
        setModalShow(true)     
    }

    function onPositive()
    {
        props.onPositive()
        setModalShow(false)
    }

    return (
        <>
            <WelcomeBackPopUp
                show={modalShow}
                title={props.modalTitle}
                onHide={() => setModalShow(false)}
                buttonText={props.buttonText}
                onPositive={() => onPositive()}
                img={props.img}
                user={props.user}
                subtitle={props.subtitle}
            />
        </>
    );
}

export default App;