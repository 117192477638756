import React, { FunctionComponent, useState } from "react";
import { Container, Row, Col, ButtonGroup, ToggleButton } from 'react-bootstrap';
import BaseInput from '../base-components/BaseFormInput';
import './CategorySearchBar.scss';

type Props = {
    categoryName: string
}

const CategorySearchBar: FunctionComponent<Props> = ({ categoryName }) => {

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Show recs', value: '1' },
        { name: 'Hide recs', value: '2' },
    ];

    return (
        <section className="category-search-bar">
            <Container>
                <Row>
                    <Col sm={12} md={4} lg={3}>
                        <h2>{categoryName}</h2>
                    </Col>
                    <Col sm={12} md={5} lg={6}>
                        <BaseInput
                            type="search"
                            name="search"
                            placeholder="Search for a product" />
                    </Col>
                    <Col sm={12} md={3}>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}


export default CategorySearchBar;