import React from "react";
import BaseButton from "../base-components/BaseButton";
import Filters from "./Filters";
import './CategoryFilters.scss';

const CategoryFilters = () => {
    return (
        <section className="category-filters-container">
            <BaseButton
                onClick
                title="Refresh results"
                icon=""
                alt="" />
            <BaseButton
                onClick
                title="Reset all filters"
                icon=""
                alt="" />
            <Filters />
        </section>
    );
}

export default CategoryFilters;