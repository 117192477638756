import React, { FunctionComponent } from "react";
import { Modal, Button } from 'react-bootstrap';
import './ResetPasswordModal.scss';
import BaseButton from '../../base-components/BaseButton';
import { ForgottenPassword } from '../../login/ForgottenPassword';
import SetPassword from "../../login/SetPassword";

interface Props {
    show: boolean,
    onHide: any,
    onClick?: any,
    hideModal?: any,
    fromSettings?: boolean,
    changePassword?: boolean
}

function ResetPasswordModal(props: Props) {

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.changePassword
                        ? <SetPassword onHide={props.onHide} />
                        : <ForgottenPassword onHide={props.onHide} />
                }

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    onClick?: any,
    hideModal?: any,
    fromSettings?: boolean,
    changePassword?:boolean
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onClick(e) {
        e.preventDefault()
        setModalShow(true)
    }

    function closeModal() {
        setModalShow(false)
    }

    return (
        <>
            {
                props.fromSettings
                    ?
                    <BaseButton
                        onClick={(e) => onClick(e)}
                        title={props.title}
                        icon=""
                        alt="">
                    </BaseButton>
                    :
                    <p><a onClick={(e) => onClick(e)} href="#">{props.title}</a></p>

            }
            <ResetPasswordModal
                changePassword={props.changePassword}
                show={modalShow}
                onHide={() => closeModal()}
                fromSettings={props.fromSettings}
            />
        </>
    );
}

export default App;