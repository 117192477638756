import { FunctionComponent, useEffect, useState } from "react";
import './ConnectionProfileContainer.scss';
import { Col, Container, Row } from 'react-bootstrap'
import PersonImage from "../person-card/PersonImage";
import BaseTooltip from "../base-components/BaseTooltip";
import BaseHolidayDropdown from "../base-components/BaseHolidayDropdown";
import EditProfileComponent from './EditProfileComponent'
import { IUser, IUserOccasion, IProduct, IProductImage } from '../../types/types-internal';
import { IUserConnection } from '../../types/types-internal';
import avatarGrey from '../../assets/icons/avatar-grey.svg'
import emailIcon from '../../assets/icons/email.svg'
import RecentRecommendations from '../../components/product-components/RecentRecommendations'
import EventInfo from '../../components/event-info/EventInfo'
import LoadingOverlay from 'react-loading-overlay';
import { Brand } from "../../enums/Brand";
import { LikeStatus } from "../../enums/LikeStatus";
import { ProductCategory } from "../../enums/ProductCategory";
import SendEmailPopUp from "../../components/modal/modal-send-email/SendEmailPopUp"
import { IsNullOrEmpty } from "../../utils/Utils";
import GoogleFbLoginMissingDataPopUp from '../../components/modal/modal-google-fb-login-missing-data/GoogleFbLoginMissingDataPopUp'
import FeedbackPopUp from "../modal/modal-feedback/FeedbackPopUp";

type ConnectionProfileProps = {
    connection: IUserConnection
    updateConnection: any,
    occasions?: any,
    editedOccasions?: boolean,
    selectedOccasion?: { occasion?: IUserOccasion, setOccasion?: Function }
}

const ConnectionProfileContainer: FunctionComponent<ConnectionProfileProps> = ({ connection, updateConnection, occasions, editedOccasions, selectedOccasion }) => {

    let names = `${connection.firstName} ${connection.lastName == null ? "" : connection.lastName}`;
    let url = new URL(window.location.toString())
    let connectionId = url.searchParams.get("id");
    
    const [loadingOverlayActive, setLoadingOverlayActive] = useState(false)
    const [products, setProducts] = useState<IProduct[]>
        ([{ brand: Brand.NIKE, productCategory: ProductCategory.Lifestyle, productImages: [{ imageUrl: "" }] }])

    const [anchor, setAnchor] = useState(0)
    const [showFillGenderAndBirthDatePopUp, setShowFillGenderAndBirthDatePopUp] = useState(false);
    const [showTrainingModal, setShowTrainingModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    useEffect(() => {
        refreshProducts(selectedOccasion?.occasion);
    }, []);

    useEffect(() => {
        if (IsNullOrEmpty(connection.gender)) {
            //setShowFillGenderAndBirthDatePopUp(true)
        }
    }, [occasions, editedOccasions])


    async function refreshProducts(occasion) {
        setLoadingOverlayActive(true)
        await getRecommendedProducts(occasion);
        setLoadingOverlayActive(false);
    }

    async function getRecommendedProducts(occasion) {
        try {
            var response;
            if (!IsNullOrEmpty(occasion) && occasion.budget) {
                let budget = occasion.budget
                response = await fetch(`api/Products/GetRecommendedProducts?connectionId=${connectionId}&budget=${budget}`);
            }
            else {
                response = await fetch(`api/Products/GetRecommendedProducts?connectionId=${connectionId}`);
            }

            if (response.status == 204) {
                setShowTrainingModal(true);
            }
            let recProducts: IProduct[] = await response.json();
            setProducts(recProducts);
            setAnchor(anchor + 9)
        } catch (error) {
            console.log("err", error)
        }
    }

    async function addProduct(product, likeStatus) {
        try {
            product["likeStatus"] = LikeStatus[likeStatus]

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...product, occasionId: selectedOccasion?.occasion?.occasionId })
            };

            var updateProductResponse = await fetch(`api/Products/AddUserProduct?connectionId=${connection.connectionId}`, settings)
            if (updateProductResponse.status == 400) {
                updateProductResponse = await fetch(`api/Products/UpdateUserProduct?connectionId=${connection.connectionId}`, settings)
            }

        } catch (error) {
            console.log("err", error)
        }
        finally {
            return null;
        }
    }
    function hideProduct(product) {
        setProducts(products.filter(x => x.externalProductId != product.externalProductId))
    }

    const initials = names.split(" ").map((n) => n[0]).join("") //TODO see if this crashes

    return (
        <>
            <LoadingOverlay
                active={loadingOverlayActive}
                spinner
                text='Calculating new recommendations...'
            >
            </LoadingOverlay>
            <GoogleFbLoginMissingDataPopUp icon="" title="" show={showFillGenderAndBirthDatePopUp} connectionId={parseInt(connectionId!)} onReady={() => setShowTrainingModal(true)} />
            <section className="profile-container">
                <Container>
                    <Row>
                        <Col xs={12} lg={5} className="profile-info-container">
                            <PersonImage
                                personImg={connection?.profilePhotoDownloadUrl as string}
                                personAlt="User"
                                initials={initials}
                                personIcon={avatarGrey} />
                            <section className="profile-info">
                                <span>Gifts for</span>
                                <h3>{names}</h3>

                                <p>{connection?.relationship}</p>
                                <div className="buttons-wpapper">
                                    <EditProfileComponent
                                        profile={connection}
                                        updateProfile={updateConnection} />
                                    {!connection.isRealUser &&
                                        <div className="send-email-wrapper">
                                            <SendEmailPopUp
                                                title=""
                                                icon={emailIcon}
                                                user={connection}
                                            />
                                            <BaseTooltip
                                                title={"Email " + connection.firstName + " to have them fill their profile"}
                                            />
                                        </div>
                                    }
                                </div>
                            </section>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="event-info-container">
                            {selectedOccasion?.occasion?.ocassionDate && new Date(selectedOccasion.occasion?.ocassionDate) && <EventInfo occasion={selectedOccasion.occasion} />}
                        </Col>
                        <Col xs={12} sm={6} lg={4} className="search-container">
                            <section className="dropdown-wrapper">
                                <BaseHolidayDropdown
                                    selectedOccasion={selectedOccasion}
                                    occasions={occasions} />
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FeedbackPopUp show={showFeedback} onHide={() => setShowFeedback(false)}/>
            <RecentRecommendations
                products={products}
                occasion={selectedOccasion?.occasion}
                refreshProducts={(occasion) => refreshProducts(occasion)}
                addProductToFavorites={(product, likeStatus) => addProduct(product, likeStatus)}
                hideProduct={(product) => hideProduct(product)}
                user={connection}
                loadProducts={() => refreshProducts(selectedOccasion)}
                setShowTrainingModal={setShowTrainingModal}
                showTrainingModal={showTrainingModal}
                connectionName={connection.firstName ? connection.firstName : connection.lastName}
                showFeedbackModal={setShowFeedback}
            />
        </>

    )
}

export default ConnectionProfileContainer;
