
const updateProfile = (updatedProfile, updatedProfilePicture, user, updateUser) => {
    console.log('UPDATED PROFILE ' + JSON.stringify(updatedProfile));

    (async () => {

        const settings = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedProfile)
        };

        let respData = await fetch('api/Users/UpdateCurrentUser', settings);

        //if the updated profile picture object is NOT empty, we update the current picture with the newly provided
        if (updatedProfilePicture) {
            const imageSettings = {
                method: 'POST',
                headers: {
                    Accept: 'multipart/form-data'
                },
                body: updatedProfilePicture
            };
            let imageData = await fetch(`api/Images/ChangeCurrentUserProfileImage`, imageSettings);
            let imageUrl = await imageData.text();
            if (imageData.status == 400) {
                console.log(imageUrl)
            }
            else {
                updatedProfile.imagePathUrl = imageUrl;
            }
        }

        const userInfoResponse = await fetch('api/Users/GetCurrentUserInfo')
        let userInfo = await userInfoResponse.json()

        let userId = userInfo.userId;
        let firstName = userInfo.firstName;
        let lastName = userInfo.lastName;
        let email = userInfo.email;
        let birthday = userInfo.birthday;
        let gender = userInfo.gender;
        let ageRange = userInfo.ageRange;
        let imageUrl = userInfo.imagePathUrl;
        console.log('BIRTHDAY AFTER PULLING THE UPDATE ' + birthday);
        updateUser({
            ...user,
            firstName: firstName,
            lastName: lastName,
            userName: firstName + " " + lastName,
            birthday: birthday,
            gender: gender,
            ageRange: ageRange,
            email: email,
            userId: userId,
            imagePathUrl: imageUrl,
            country: userInfo.country,
            state: userInfo.state,
            city: userInfo.city,
            isLoggedIn: true
        });
    })();
}

export { updateProfile };