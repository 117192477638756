export enum ProductCategory
    {
        Lifestyle = 1,
        Running = 2,
        Basketball = 3,
        Jordan = 4,
        Gym = 5,
        Soccer = 6,
        Golf = 7,
        CrossCountry = 8,
        Skateboard = 9,
        Baseball = 10,
        Softball = 11,
        Football = 12,
        Walking = 13,
        Sandals = 14,
        TShirts = 15,
        Shorts = 16,
        Hoodies = 17,
        Pants = 18,
        Jackets = 19,
        Swimwear = 20,
        Polo = 21,
        SportsBra = 22,
        NikePro = 23,
        Yoga = 24,
        Socks = 25,
        Underwear = 26,
        SustainableMaterial = 27,
        Bags = 28,
        Hats = 29,
    }