import React, { useContext } from "react";
import './ProfileCreatedModal.scss';
import Image from '../../base-components/BaseImage';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import AvatarModal from '../modal-components/AvatarModal';
import HeaderModal from '../modal-components/HeaderModal';
import avatarCreated from '../../../assets/icons/avatar-created.svg'
import congratulations from '../../../assets/svg/congratulations.svg'
import { useHistory } from "react-router-dom";
import { UserContext } from '../../../context/UserContext'
import { IsNullOrEmpty } from "../../../utils/Utils";

const ProfileCreatedModal = (props) => {

    const history = useHistory();
    const { user, updateUser } = useContext(UserContext);

    function userName() {
        return IsNullOrEmpty(props.user.userName) ? user.firstName + " " + user.lastName : props.user.userName;
    }

    function goToProfile() {
        if(props?.connectionId){
            history.push({
                pathname: '/connection-profile',
                search: `id=${props?.connectionId}`,
            })
        }else{
            history.push('/relationships');
        }
        props?.onHide();
    }

    return (
        <section className={`profile-created-modal mb-2 active`}>
            <Image
                img={congratulations}
                alt="" />
            <ProgressLineModal />

            <HeaderModal
                span=""
                heading={userName() + "'s profile is set up!"}
                paragraph="Now let’s go look at some gift recommendations."
            />
            <AvatarModal
                userName="You"
                icon={avatarCreated}
                onProfileClick={() => goToProfile()}
                alt="User image" />
        </section>
    )
}

export default ProfileCreatedModal;