import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { propTypes } from "react-bootstrap/esm/Image";
import './EditProfileModal.scss';
import EditProfileModalBody from './EditProfileModalBody';

interface Props {
    profile?: any,
    updateProfile?: any,
    showModal?: boolean,
    setShowModal?: any
}


function EditProfileModal(props: Props) {

    function handleClose() {
        props?.setShowModal(false);
    }

    return (
        <Modal
            className="edit-profile-modal"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            onHide={handleClose}
        >
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditProfileModalBody
                    profile={props.profile}
                    updateProfile={(profile, image) => props.updateProfile(profile,image)}
                    setShowModal={props.setShowModal} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setShowModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditProfileModal;