import { FunctionComponent, useEffect, useState } from "react";
import BaseDropdown from './BaseDropdown';
import { IUserOccasion } from '../../types/types-internal';
import { publicHolidaysKvP } from "../../utils/Utils";

type BaseHolidayDropdownProps = {
    selectedOccasion?: { occasion?: IUserOccasion, setOccasion?: Function }
    occasions: IUserOccasion[]
}

const BaseHolidayDropdown: FunctionComponent<BaseHolidayDropdownProps> = ({ selectedOccasion, occasions }) => {

    const [dropDownOccasions, setDropDownOccasions] = useState<string[]>([]);

    let topDropdownTitle = mapOccasionToHoliday(selectedOccasion?.occasion);

    useEffect(() => {
        setDropDownOccasions(occasions.map(x => mapOccasionToHoliday(x)));
    }, [occasions])

    function mapOccasionToHoliday(occasion: IUserOccasion | undefined) {
        let holiday = publicHolidaysKvP.find(opt => opt.key == occasion?.typeOfOccasion)?.value;
        if(holiday){
            return holiday;
        }
        return '';
    }

    function changeGiftEvent(e) {
        let eventKey = publicHolidaysKvP.find(h => h.value == e)?.key;
        var desiredEvent = occasions.filter(occ => occ.typeOfOccasion == eventKey)[0]
        if (desiredEvent && selectedOccasion?.setOccasion) {
            selectedOccasion?.setOccasion(desiredEvent)
        }
    }

    return (
        <BaseDropdown
            title={ topDropdownTitle ? topDropdownTitle : "Select Event"}
            options={dropDownOccasions}
            onClick={(e) => changeGiftEvent(e)}
        />
    );
}

export default BaseHolidayDropdown;