import React, { FunctionComponent, useRef} from "react";
// import './BaseImage.scss';

type ImageProps = {
    img: string,
    alt?: string,
    onClick?: any,
  }

  const BaseImage: FunctionComponent<ImageProps> = ({ img, alt, onClick }) => {

    return (
        <div className="image-wrapper" onClick={onClick}>
           <img src={img} alt={alt} ></img>
        </div>
    )
}

export default BaseImage;