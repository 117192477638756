import { FunctionComponent, useState } from "react";
import './BaseImage.scss';

type ImageProps = {
    img?: string,
    img2?: string,
    alt?: string,
    onClick?: any,
  }

  const BaseImage: FunctionComponent<ImageProps> = ({ img, img2, alt, onClick }) => {
    const [img2IsShown, setImg2IsShown] = useState(false);
    return (
      <div className="image-wrapper" onMouseEnter={() => setImg2IsShown(true)} onMouseLeave={() => setImg2IsShown(false)} onClick={onClick}>
          <img src={img} alt={alt}  ></img>
          {img2 != undefined && img2IsShown && <img src={img2} alt={alt} ></img> }
        </div>
    )
}

export default BaseImage;