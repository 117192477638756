import React from 'react'
import './About.scss'
import TagManager from 'react-gtm-module'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import BaseImage from '../../components/base-components/BaseImage'
import aboutImg from '../../assets/images/about1.jpg'
import { Fade } from '@material-ui/core';

const About = () => {
    const tagManagerArgs = {
        dataLayer: {
            userId: "007",
            userProject: 'project',
            page: 'about'
        },
        dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)

    return (
        <>
            <Helmet>
                <title>WisePrezzie | About</title>
                <meta name="description" content="..." />
                <meta name="keywords" content="..." />
                <meta name="author" content="WisePrezzie" />
            </Helmet>
            <article className="about-container">
                <Container fluid>
                    <Fade in={true} timeout={500}>
                        <Row>
                            <Col>
                                <h1>About Us</h1>
                                <p>WisePrezzie simplifies your search by helping you find gifts for any recipient and any occasion without the hassle.</p>
                                <BaseImage
                                    img={aboutImg}
                                    alt="About us page"
                                />
                            </Col>
                        </Row>
                    </Fade>
                </Container>
                <Container>
                    <Fade in={true} timeout={500}>
                        <Row>
                            <Col className="content">
                                <h2>What We believe</h2>
                                <p>Our mission is to change how people find gifts for those they love by integrating personal taste and gifting expertise with technology. We want to help you save time, deliver impactful gifts, and maintain relationships for years to come.</p>

                                <h2>How We got here</h2>
                                <p>When we set out to create WisePrezzie, we wanted to come up with a better way to find gifts for our friends and family - one that is more intentional, thoughtful, and personalized. We were tirelessly working and focusing on the routine activities of everyday life, but neglected to consistently make the desire impression in our personal lives. We would buy gifts for our loved ones at the last minute, frantically sifting through stores and online, hoping we’d eventually come across the right gift.</p>

                                <p>Coming from the tech world, we believe technology is a tool to use to improve society. Sadly, some tech products have brought out the worst in humanity. We created WisePrezzie to leverage the power of technology to help all of us become better at gift giving, a practice that goes back for thousands of years and crosses cultural boundaries all over the world. A gift is a symbol of what ties us together, signaling gratitude for a past, present, but most importantly a timeless bond.</p>
                            </Col>
                        </Row>
                    </Fade>
                </Container>
            </article>
        </>
    )
}

export default About;