import moment from 'moment';

export function monthToDays(selectedMonth, selectedYear) {
    let days: string[] = [];

    switch (selectedMonth) {
        case "": {
            days = fillDays(31);
            break;
        }
        case 0:
        case 2:
        case 4:
        case 6:
        case 7:
        case 9:
        case 11:
            {
                days = fillDays(31);
                break;
            }
        case 1:
            {
                if (selectedYear == "") {
                    days = fillDays(28);
                } else if (leapYear(Number(selectedYear))) {
                    days = fillDays(29);
                } else {
                    days = fillDays(28);
                }
                break;
            }

        default: {
            days = fillDays(30);
        }
    }

    return days;
}

export function fillDays(limit: number) {
    let days: string[] = [];
    for (let i = 1; i <= limit; i++) {
        days.push(i.toString());
    }

    return days;
}

export function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function getMonth() {
    const month: string[] = ["January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"];

    return month;
}


export enum Month {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}

export enum AgeRange {
    BelowTwentyOne = 0,
    TwentyOneTrirty = 1,
    TrirtyForty = 2,
    FortyFifty = 3,
    FiftySixty = 4,
    SixtyAndMore = 5
}

export function getYears(): string[] {
    let years: string[] = [];
    let currentYear = new Date().getFullYear();
    //Just get every year from 1900 to current (today).
    for (let i = 1900; i <= currentYear; i++) {
        years.push(i.toString());
    }

    return years;
}

export function getYearsFuture(): string[] {
    let years: string[] = [];
    let currentYear = new Date().getFullYear();

    for (let i = currentYear; i <= 2050; i++) {
        years.push(i.toString());
    }

    return years;
}

export function GetYear(date: string) {
    let year = new Date(date)
    return year.getUTCFullYear()
}

export function GetMonth(date: string) {
    let month = new Date(date)
    return month.getUTCMonth();
}

export function GetDay(date: string) {
    let day = new Date(date)
    return day.getUTCDate()
}

const DateUtils = {
    today: () => {
        let now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    utcToLocal: (date) => {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    },
    localToUtc: (date) => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    },
    getNextCelebrationDate: (utcDate) => {
        let celebrationDate = new Date(utcDate);
        celebrationDate.setUTCFullYear(new Date().getFullYear());

        if (DateUtils.localToUtc(DateUtils.today()) > celebrationDate) {
            celebrationDate.setUTCFullYear(new Date().getFullYear() + 1);
        }

        return celebrationDate;
    }

};

export { DateUtils };