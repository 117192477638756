import React, { useState } from "react";
import './Filters.scss';
import BaseButton from "../base-components/BaseButton";
import arrow from "../../assets/icons/arrow.svg";
import plus from "../../assets/icons/plus.svg";
import BaseFormInput from "../base-components/BaseFormInput";
import BaseCheckbox from "../base-components/BaseCheckbox";

//TODO: Accordion
const Filters = () => {
    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <section className="filters-accordion" >
            <div className={`filter-group ${isActive ? "open" : ""}`}>
                <BaseButton
                    title="Price"
                    icon={arrow}
                    alt="Price"
                    onClick={handleToggle} />
                <div className="price-group">
                    <BaseFormInput
                        type="text"
                        name="Min price"
                        placeholder="Min" />
                    <span>to</span>
                    <BaseFormInput
                        type="text"
                        name="Max price"
                        placeholder="Max" />
                </div>
            </div>
            <div className={`filter-group ${isActive ? "open" : ""}`}>
                <BaseButton
                    title="Category"
                    icon={arrow}
                    alt="Category"
                    onClick={handleToggle} />
                <div className="category-group">
                    <BaseCheckbox
                        label="All Clothing" />
                    <BaseCheckbox
                        label="Coats & Jackets" />
                    <BaseCheckbox
                        label="Dresses" />
                    <BaseCheckbox
                        label="Jeans & Denim" />
                    <BaseCheckbox
                        label="Jumpsuits & Rompers" />
                    <BaseCheckbox
                        label="Loungewear" />
                    <BaseButton
                        onClick
                        title="More"
                        icon={plus}
                        alt="more" />
                </div>
            </div>
            <div className={`filter-group ${isActive ? "open" : ""}`}>
                <BaseButton
                    title="Brand"
                    icon={arrow}
                    alt="Brand"
                    onClick={handleToggle} />
                <div className="category-group">
                    <BaseCheckbox
                        label="All Brands" />
                    <BaseCheckbox
                        label="Absence of Colour" />
                    <BaseCheckbox
                        label="Acne Studios" />
                    <BaseCheckbox
                        label="Adam Lippes" />
                    <BaseCheckbox
                        label="Adeam" />
                    <BaseCheckbox
                        label="Adelyn Rae" />
                    <BaseButton
                        onClick
                        title="More"
                        icon={plus}
                        alt="more" />
                </div>
            </div>
        </section>
    );
}
export default Filters;