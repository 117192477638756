import { publicHolidaysKvP } from "../../../utils/Utils";
import { Month, DateUtils } from '../../../utils/DateUtils';
import { IUserOccasion } from "../../../types/types-internal";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


function getOccasionDate(chosenOccasion: IUserOccasion | undefined) {
    if (chosenOccasion && chosenOccasion?.ocassionDate) {

        let date = new Date(chosenOccasion?.ocassionDate);
        
        const dateToday = DateUtils.localToUtc(DateUtils.today());
        const diffDays = dateDiffInDays(dateToday, date);  

        let type: string | undefined;
        type = publicHolidaysKvP.find(opt => opt.key == chosenOccasion.typeOfOccasion.toString())?.value
        if (diffDays == 0) {
            return (<p>{type} is today!</p>);
        }

        if (diffDays < 0) {
            return (<p>{type} overdue by {Math.abs(diffDays)} days</p>);
        }

        let day = date.getUTCDate()
        let month = date.getUTCMonth();
        return (<div>
            <p>{type} on {Month[month + 1]} {day}</p>
            <p>(in {diffDays} days)</p>
        </div>);
    }
    else {
        return (<p>No recent events for this connection</p>);
    }
}

export { getOccasionDate };