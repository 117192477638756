import React, { FunctionComponent } from "react";
import './DesignPicture.scss';

type PictureProps = {
    imageSrc: string,
    alt: string
}

const DesignPicture: FunctionComponent<PictureProps> = ({ imageSrc, alt }) => {
    return (
        <div className="picture">
            <img src={imageSrc} alt={alt}></img>
        </div>
    )
}

export default DesignPicture;