import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext'
import { useHistory } from "react-router-dom";
import axios from 'axios';

const AuthenticateContext = () => {

    const { user, updateUser } = useContext(UserContext);
    const history = useHistory()

    useEffect(() => {
        window.addEventListener('load', (event) => {
            async function checkForLoggedUser() {
                await checkLoginStatus();
            }
            checkForLoggedUser();
        });
    }, []);

    async function checkLoginStatus() {
        try {
            console.log('check for logged user');
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('development server!!!!!');
            } else {
                console.log('PRODUCTION BABY !!');
            }

            const response = await axios.get('/api/Auth/CheckLogin');
            if (response.status == 200) {
                const user = response.data;
                let userId = user.userId as string;

                const userInfoResponse = await fetch('/api/Users/GetCurrentUserInfo')
                let userInfo = await userInfoResponse.json()

                let firstName = userInfo.firstName;
                let lastName = userInfo.lastName;
                let email = userInfo.email;
                let birthday = userInfo.birthday;
                let gender = userInfo.gender;
                let ageRange = userInfo.ageRange;
                let imageUrl = userInfo.imagePathUrl;

                updateUser({
                    ...user,
                    firstName: firstName,
                    lastName: lastName,
                    userName: firstName + " " + lastName,
                    birthday: birthday,
                    gender: gender,
                    ageRange: ageRange,
                    email: email,
                    userId: userId,
                    isLoggedIn: true,
                    imagePathUrl: imageUrl,
                    country: userInfo.country,
                    state: userInfo.state,
                    city: userInfo.city,
                    subscription: userInfo.subscription
                });
               
                if(history.location.pathname == "/" || history.location.pathname == "/landing")
                {
                    history.push("/relationships")
                }
            }  

        } catch (error) {
                console.log(`error log: ${console.error}`);
        }
        finally {
            return null;
        }
    }

    return (null);
}

export default AuthenticateContext
