import React, { FunctionComponent, useState, useEffect } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import './ToggleButtonModal.scss';
import { Gender } from '../../../enums/Gender';

const ToggleButtonModal = (props) => {

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState("1");

    const radios = [
        { name: 'Male', value: "MALE" },
        { name: 'Female', value: "FEMALE" },
        { name: 'Nonbinary', value: "NONBINARY" },
    ];

    const onChange = (e: any) => {
        setRadioValue(e.currentTarget.value)
        props.onGenderChange(e.currentTarget.value)
    }

    useEffect(() => {
        if(props?.gender){
            let gender = props.gender;
            setRadioValue(gender)
        }
    }, []);

    return (
        <>
            <ButtonGroup>
                {radios.map((radio) => (
                    <ToggleButton
                        key={radio.value}
                        id={`radio-${radio.value}`}
                        type="radio"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={onChange}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}

export default ToggleButtonModal;