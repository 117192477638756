import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import BaseButton from '../../base-components/BaseButton';
import cancelIcon from '../../../assets/icons/cancel-white.svg';
import trashIcon from '../../../assets/icons/trash.svg';
import leftArrow from '../../../assets/icons/left-arrow.svg';
import './YesNoModal.scss';
import BaseCheckbox from "../../base-components/BaseCheckbox";

interface Props {
    show: boolean,
    onHide: any,
    title: string,
    onPositive?: any,
    positiveText?: string,
    negativeText?: string,
    subtitle?: string,
    showSpinner?: boolean,
    showOptions?: boolean,
    options?: string[],
}

function YesNoPopUp(props: Props) {

    function chooseReason(e){
        console.log(e)
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="yes-no-modal">
                {props.showOptions && 
                    <section className="reasons-for-deactivation">
                        <h2>Please, select reason</h2>
                        {props.options?.map(option => (
                            <BaseCheckbox
                                 label={option}
                            />
                        ))}
                    </section>
                }  
                {props.subtitle &&
                    <p> {props.subtitle} </p>
                }
                <Row className="p-2">
                    <Col md={6}>
                        <BaseButton
                            onClick={() => props.onHide()}
                            title={props.negativeText || "Cancel"}
                            icon={leftArrow}
                            alt="Cancel" 
                        />  
                    </Col> 
                    <Col md={6}>
                        <BaseButton
                            onClick={() => props.onPositive()}
                            title={props.positiveText || "Yes"}
                            className="delete"
                            icon={cancelIcon}
                            alt="Yes"
                        />  
                    </Col> 
                </Row>  
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    buttonTitle?: string,
    modalTitle: string,
    icon: string,
    onPositive?: any,
    positiveText?: string,
    negativeText?: string,
    subtitle?: string,
    showSpinner?: boolean,
    showOptions?: boolean,
    options?: string[],
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onClick() {
        setModalShow(true)
    }

    function onPositive() {
        props.onPositive()
        setModalShow(false)
    }

    return (
        <>
            <BaseButton
                onClick={onClick}
                title={props.buttonTitle as string}
                icon={props.icon || cancelIcon}
                alt="Delete"
                className="delete-button"
                showSpinner={props.showSpinner}
            >
            </BaseButton>
            <YesNoPopUp
                show={modalShow}
                title={props.modalTitle}
                onHide={() => setModalShow(false)}
                positiveText={props.positiveText}
                negativeText={props.negativeText}
                onPositive={() => onPositive()}
                subtitle={props.subtitle}
                showOptions={props.showOptions}
                options={props.options}
            />
        </>
    );
}

export default App;
