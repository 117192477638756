import React, { useEffect, useState } from "react";
import './EventModal.scss';
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import BaseButton from '../../base-components/BaseButton';
import BaseFormInput from '../../base-components/BaseFormInput';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import HeaderModal from '../modal-components/HeaderModal';
import EventContainerModal from '../modal-components/EventContainerModal'
import arrowRight from '../../../assets/icons/right-arrow.svg';
import { getYears, getMonth, Month, monthToDays } from '../../../utils/DateUtils';
import { DateUtils } from "../../../utils/DateUtils";

const EventModal = (props) => {
    const [loading, setLoading] = React.useState(false)

    const [occasion, setOccasion] = useState('0');
    const [budget, setBudget] = useState('100');
    const [birthdayDate, setBirthdayDate] = useState(new Date());

    const [selectedMonth, setMonth] = useState("");
    const [selectedDay, setDay] = useState("");
    const [selectedYear, setYear] = useState("");

    useEffect(() => {
        getConnectionBirthDay()
    },[props.connectionId])

    function getOccasionDate()
    {
        if(occasion == "13")
        {
            return DateUtils.localToUtc(DateUtils.today());
        }
        else if(occasion == "2")
        {
            return new Date(Date.UTC(new Date().getFullYear(), 11, 25));
        }
        else if(occasion == "12")
        {
            return DateUtils.getNextCelebrationDate(birthdayDate);
        }

       return new Date(Date.UTC(parseInt(selectedYear), Month[selectedMonth] - 1, parseInt(selectedDay)))
    }

    async function getConnectionBirthDay()
    {
        let response = await fetch(`api/Users/GetSpecificUserConnection?connectionId=${props.connectionId}`)
        await response.json().then(res => {    
            setBirthdayDate(res.birthday)
        });     
    }


    function addUserConnectionOccasion()
    {
        setLoading(true)
        let connectionId = props.connectionId
        fetch('api/Occasions/AddOccasionToUserConnection', {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
            },
            "body": JSON.stringify({
                budget:  parseInt(budget),
                typeOfOccasion: parseInt(occasion),
                ocassionDate: getOccasionDate(),
                connectionId:  parseInt(connectionId)
            })
        })
        .then((res) => {
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        });
        props.onNextModal();
    }   

    return (
        <section className={`event-modal active`}>
            <ProgressLineModal />
            <HeaderModal
                heading="Searching for a particular occasion?"
                span=""
                paragraph="Choose one (you’ll be able to easily select more later). We use this information to narrow down gift options"
            />
            <EventContainerModal onChange={(e) => setOccasion(e)} />
            <Container className="budget-container">
                <Row>
                    <Col>
                        <h5>Budget</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BaseFormInput
                            type="number"
                            name="budget"
                            placeholder=""
                            onChange={(e) => setBudget(e)}
                            src="" />
                        <p>( If no value provided, we'll default to $100 )</p>
                    </Col>
                </Row>
            </Container>
            <Container className={(occasion == "4" || occasion == "5") ? "birthday-container" : "birthday-container hidden"}>
                <Row>
                    <Col sm={12} md={4}>
                        <select className="DatePicker" placeholder="Month" defaultValue={Month[selectedMonth]} onChange={(e) => setMonth(e.currentTarget.value)}>
                            <option hidden value="">{ selectedMonth || "Month" }</option>
                            {getMonth().map((option) => (
                                <option value={option}>{option}</option>
                            ))}
                            
                        </select>
                    </Col>
                    <Col sm={12} md={4}>
                        <select className="DatePicker" placeholder="Day" defaultValue={selectedDay} onChange={(e) => setDay(e.currentTarget.value)}>
                            <option hidden value="">{ selectedDay || "Day"}</option>
                            {monthToDays(selectedMonth, selectedYear).map((option) => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>
                    </Col>
                    <Col sm={12} md={4}>
                        <select className="DatePicker" placeholder="Year" defaultValue={selectedYear} onChange={(e) => setYear(e.currentTarget.value)}>
                            <option hidden value="">{ selectedYear || "Year"}</option>
                            {getYears().map((option) => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>

            <BaseButton
                className={`arrow-animation ${(occasion != "0") ? "" : "disabled"}`}
                onClick={addUserConnectionOccasion}
                title="complete basic setup"
                icon={arrowRight}
                alt="Right Arrow"
                showSpinner={loading}>
            </BaseButton>
        </section>
    )
}

export default EventModal;