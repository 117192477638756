import React, { useState } from "react";
import './AdminNavbar.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import home from '../../assets/icons/home.svg'

const AdminNavbar = () => {
    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <nav className={`admin-navbar ${isActive ? "open-list" : ""}`}>
        </nav>
    )
}

export default AdminNavbar;