import React, { useState } from "react";
import './LocationFormComponent.scss';
import { FunctionComponent } from "react";
import { Col, Row } from 'react-bootstrap'
import { Country } from '../../enums/Country';
import { State } from '../../enums/State';
import { ILocation } from '../../types/types-internal';



type LocationProps = {
    location: ILocation,
    setLocation?: any
}

const LocationFormComponent: FunctionComponent<LocationProps> = (props) => {
    let location = props.location;
    let cityName = location.city ? location.city : '';

    const [countryIsActive, countrySetActive] = useState(false);
    const [stateIsActive, stateSetActive] = useState(false);

    const countryToggle = () => {
        countrySetActive(!countryIsActive);
    };

    const stateToggle = () => {
        stateSetActive(!stateIsActive);
    };

    return (
        <Col className="location-comp">
            <h5>Location *</h5>
            <Row>
                <Col xs={12} sm={6} lg={4} className="mb-3">
                    <input inputMode="text" placeholder="City *" defaultValue={cityName} onChange={(e) => props.setLocation({ ...location, city: e.currentTarget.value })} />
                </Col>
                <Col xs={12} sm={6} lg={4} >
                    <div className={`select-wapper ${stateIsActive ? "open" : ""}`}>
                        <select className="select-picker" defaultValue={location.state} onClick={stateToggle} onChange={(e) => props.setLocation({ ...location, state: e.currentTarget.value })} >
                            <option value="" selected hidden>{"State" || location.state}</option>
                            {Object.keys(State).map(state => (
                                <option value={State[state]}>{state}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={4}>
                    <div className={`select-wapper ${countryIsActive ? "open" : ""}`}>
                        <select className="select-picker" defaultValue={"US"} onClick={countryToggle} onChange={(e) => props.setLocation({ ...location, country: e.currentTarget.value })}>
                            <option value="US" selected>United States</option>
                        </select>
                    </div>
                </Col>
            </Row>
        </Col>);
}

export { LocationFormComponent };