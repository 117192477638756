import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './EventContainerModal.scss';
import thanksgivingImg from '../../../assets/images/images-sm/thanksaving-sm.jpg';
import christmasImg from '../../../assets/images/images-sm/christmas-sm.jpg';
import newYearsDayImg from '../../../assets/images/images-sm/new-years-day-sm.jpg';
import birthdayImg from '../../../assets/images/images-sm/birthday-sm.jpg';
import justBecauseImg from '../../../assets/images/images-sm/just-because-sm.jpg';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import Image from '../../base-components/BaseImage';

const EventContainerModal = (props) => {

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Birthday', image: birthdayImg, value: '12' },
        { name: 'Christmas', image: christmasImg, value: '2' },
        { name: 'Just Because', image: justBecauseImg, value: '13' },
    ];

    const onChange = (e: any) => {
        setRadioValue(e.currentTarget.value)
        props.onChange(e.currentTarget.value)
    }

    function clickInput(idx)
    {
        let label: HTMLElement = document.getElementById("events-radio-" + idx) as HTMLElement;
        let input = label.childNodes[0] as HTMLElement;
        input.click()
    }

    return (
        <section className="event-container">
            <h5>Occasions</h5>
            <div className="event-wrapper">
                
            {radios.map((radio, idx) => (
                    <div className="event-item-container" onClick={(e) => clickInput(idx)}>
                        <Image 
                            img={radio.image}
                            alt="" 
                        />
                        <ButtonGroup className="radio-group">
                            <ToggleButton
                                className="radio-button"
                                key={idx}
                                id={`events-radio-${idx}`}
                                type="radio"
                                name={`radio-${idx}`}
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={onChange}
                            >
                            </ToggleButton>
                            <label className="event-name">{radio.name}</label> 
                        </ButtonGroup>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default EventContainerModal;