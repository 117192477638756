import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './SendEmailPopUp.scss';
import BaseButton from '../../base-components/BaseButton';
import SendEmailModal from './SendEmailModal'
import { IUser } from "../../../types/types-internal";


interface Props {
    show: boolean,
    onHide: any,
    user: IUser,
}


function SendEmailPopUp(props: Props) {


    function title() {
        if (props.user.userName != "undefined") {
            return `Invite ${props.user.firstName} to fill their profile`
        }
        else {
            return "Add your people"
        }
    }

    return (
        <Modal
            {...props}
            className="add-connection-pop-up"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SendEmailModal title={title()} user={props.user} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    user: IUser,
}


const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onClick() {
        setModalShow(true)
    }

    return (
        <>
            <BaseButton
                className="reverse"
                onClick={onClick}
                title={props.title}
                icon={props.icon}
                alt="">
            </BaseButton>
            <SendEmailPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                user={props.user}
            />
        </>
    );
}



export default App;