import React, { FunctionComponent } from "react";
import './BaseButton.scss';
import LoadingSpinner from '../loading-spinner/LoadingSpinner'

type ButtonProps = {
    title: string,
    icon?: string,
    alt?: string,
    onClick?: any,
    className?: string,
    form?: any
    showSpinner?: boolean,
}

const BaseButton: FunctionComponent<ButtonProps> = ({ title, icon, alt, onClick, className, form, showSpinner }) => {
    return (
        <button className={`button ${className ? className : "" || showSpinner ? "disabled" : ""}`} onClick={onClick} form={form} >
            <LoadingSpinner isShown={showSpinner} />
            <span>{title}</span>
            <img src={icon} alt={alt}></img>
        </button>
    )
}

export default BaseButton;