import { useState, useEffect, useRef, useCallback } from 'react'
import './TakeTheTour.scss'
import TourSection from './tour-section/TourSection'
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import image1 from '../../assets/images/images-sm/tour-image1.png'
import image2 from '../../assets/images/images-sm/tour-image2.png'
import image3 from '../../assets/images/images-sm/tour-image3.png'
import { Container, Row, Col } from 'react-bootstrap';
import Newsletter from '../../components/newsletter/Newsletter';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const TakeTheTour = () => {
    const stepTwo = useRef(null)
    const executeScrollstepTwo = () => { scrollToRef(stepTwo) }
    const stepThree = useRef(null)
    const executeScrollstepThree = () => { scrollToRef(stepThree) }

    const [scrollOne, setScrollOne] = useState(false)
    const [scrollTwo, setScrollTwo] = useState(false)
    const [scrollThree, setScrollTree] = useState(false)
    const [y, setY] = useState(window.scrollY);


    const hasWindow = typeof window !== 'undefined';
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const stepOne = document.querySelector('.take-the-tour-container')
        const stepTwo = document.querySelector('.step-two');
        const stepThree = document.querySelector('.step-three')

        const width = hasWindow ? window.innerWidth : null;

        if (window.innerWidth <= 1399) {
            setTimeout(() => {
                stepOne?.classList.add("step-оne-аnimation")
                stepTwo?.classList.add("step-two-аnimation")
                stepThree?.classList.add("step-three-аnimation")
            }, 1500);
        } 
        return {
            width
        };
    }

    const handleScroll = useCallback(
        e => {
            const window = e.currentTarget;
            const stepOne = document.querySelector('.take-the-tour-container')
            const stepTwo = document.querySelector('.step-two');
            const stepThree = document.querySelector('.step-three')

            const scrollCheckOne = window.scrollY > 30
            const scrollCheckTwo = window.scrollY > 1000
            const scrollCheckThree = window.scrollY > 1200

            if (y > window.scrollY) {
                console.log("scrolling up");
            } else if (y < window.scrollY) {
                if (scrollCheckOne !== scrollOne) {
                    setScrollOne(scrollCheckOne)
                    stepOne?.classList.add("step-оne-аnimation")
                } else if (scrollCheckTwo !== scrollTwo) {
                    setScrollTwo(scrollCheckTwo)
                    stepTwo?.classList.add("step-two-аnimation")
                } else if (scrollCheckThree !== scrollThree) {
                    setScrollTree(scrollCheckThree)
                    stepThree?.classList.add("step-three-аnimation")
                }
                console.log("scrolling down");
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            }
            setY(window.scrollY);
            window.addEventListener("scroll", handleScroll);
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener("scroll", handleScroll);
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [hasWindow, handleScroll]);

    return (
        <>
            <article className="take-the-tour-container">
                <section className="step-one">
                    <ScrollAnimation animateIn='animate__fadeInLeft'
                        animateOnce={true}>
                        <TourSection
                            img={image1}
                            alt="Tour step one"
                            step="1"
                            title="Create your account"
                            subtitle="Let WisePrezzie know your interests and preferences. This helps with what to recommend."
                            onClick={executeScrollstepTwo}
                        />
                    </ScrollAnimation>
                </section>
                <section className="step-two" ref={stepTwo}>
                    <ScrollAnimation animateIn='animate__fadeInRight'
                        animateOnce={true}>
                        <TourSection
                            img={image2}
                            alt="Tour step two"
                            step="2"
                            title="Share your relationships"
                            subtitle="Identify who and when you’d like to get someone a gift and send your gift recipients an invitation to identify their interests and preferences."
                            onClick={executeScrollstepThree}
                        />
                    </ScrollAnimation>
                </section>
                <section className="step-three" ref={stepThree}>
                    <ScrollAnimation animateIn='animate__fadeInLeft'
                        animateOnce={true}>
                        <TourSection
                            img={image3}
                            alt="Tour step three"
                            step="3"
                            title="Select gifts"
                            subtitle="WisePrezzie does the heavy lifting. It recommends gifts from you but for your gift recipient."
                        />
                    </ScrollAnimation>
                </section>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default TakeTheTour;
