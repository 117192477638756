import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './EditGiftEventsPopUp.scss';
import BaseButton from '../../base-components/BaseButton';
import GiftEventsModal from '../modal-gift-events/GiftEventsModal';
import HeaderGroup  from '../../base-components/HeaderGroup';
import editIcon from '../../../assets/icons/edit.svg'
import { IUserConnection } from "../../../types/types-internal";

interface Props {
    connection?: IUserConnection,
    show: boolean,
    onHide: any,
    occasions?: any,
    onEditedOccasions?: any,
    isCurrentUser?: boolean,
}

function EditGiftEventsPopUp(props: Props) {

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GiftEventsModal 
                    connection={props.connection} 
                    occasions={props.occasions} 
                    isCurrentUser={props.isCurrentUser}
                    isActive={true} 
                    onHide={() => props.onHide()} 
                    onEditedOccasions={(e) => props.onEditedOccasions(e)}    
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type HeaderGroupProps = {
    heading: string,
    title: string,
    icon?: string,
    alt?: string,
    connection?: IUserConnection,
    occasions?: any,
    onEditedOccasions?: any,
    isCurrentUser?: boolean,
}

const App: FunctionComponent<HeaderGroupProps> = ({ heading, title, icon, alt, connection, occasions, onEditedOccasions, isCurrentUser }) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onButtonClick()
    {
        setModalShow(true)      
    }

    return (
        <>
            <HeaderGroup
                    onClick={onButtonClick}
                    heading={heading}
                    buttonTitle={title}
                    buttonIcon={icon || editIcon}
                    buttonAlt={alt || ""}
            />
            <EditGiftEventsPopUp
                onEditedOccasions={(e) => onEditedOccasions(e)}
                occasions={occasions}
                connection={connection}
                isCurrentUser={isCurrentUser}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}



export default App;