import axios from 'axios';
import { useEffect, useState } from 'react';
import './PaymentSuccess.scss'
import { SubscriptionType } from '../../enums/UserEnums';
import { Container, Row } from 'react-bootstrap';

const PaymentSuccess = () => {
    const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(SubscriptionType.Undefined);

    useEffect(() => {
        (async () => {
            let result = await axios.get('/api/Payment/CheckPaymentStatus');
            setSubscriptionType(result.data.subscriptionType);
            console.log(result);
        })();
    }, []);

    return (
        <Container>
            <Row >
                <div className="card">
                    {subscriptionType === SubscriptionType.Undefined || subscriptionType === SubscriptionType.NoSubscription
                        ? <h1>Loading...</h1>
                        : <>
                            <h1>Payment successful</h1>
                            <p>We received your payment<br /> Please access and take full functionality of our services!</p></>}
                </div>
            </Row>
        </Container>
    )
}

export default PaymentSuccess;