import React, { FunctionComponent } from "react";
import './BaseTextarea.scss';

type TextareaProps = {
    id: string,
    name: string,
    rows: number,
    placeholder: string,
    onTextChange: Function
}

const BaseTextarea: FunctionComponent<TextareaProps> = ({ id, name, rows, placeholder, onTextChange }) => {
    return (
        <textarea id={id} name={name} rows={rows} placeholder={placeholder} onChange={(e) => onTextChange(e.target.value)}>
           
        </textarea>
    )
}

export default BaseTextarea;