import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import './HomeComponent.scss';
import BaseButton from './base-components/BaseButton';
import DesignElement from './design-element/DesignElement';
import Main from './main/Main';
import Testimonial from './testimonial/Testimonial';
import Newsletter from './newsletter/Newsletter';
import Footer from './footer/Footer';
import arrowRight from '../assets/icons/right-arrow.svg';
import iconCake from '../assets/icons/cake.svg';
import iconChristmasTree from '../assets/icons/christmas-tree.svg';
import iconFirework from '../assets/icons/firework.svg';
import iconGiftbox from '../assets/icons/giftbox.svg';
import iocnThankYou from '../assets/icons/thank-you.svg';
import { Fade, Grow, Slide } from '@material-ui/core';
import TagManager from 'react-gtm-module'
import TakeTheTour from '../pages/take-the-tour/TakeTheTour';

function Home() {

    const history = useHistory()

    const tagManagerArgs = {
        dataLayer: {
            userId: "007",
            userProject: 'project',
            page: '/'
        },
        dataLayerName: 'PageDataLayer'
    }

    function goToTour()
    {
        history.push("/tour")
    }

    TagManager.dataLayer(tagManagerArgs)
    return (
        <>
            <Main />
            <article className="how-work-article">
                <Container className="d-flex justify-content-center">
                    <Row>
                        <Grow in={true} timeout={500}>
                            <Col>
                                <h3>Learn how WisePrezzie can work for you</h3>
                                <BaseButton
                                    onClick={goToTour}
                                    className="secondary arrow-animation"
                                    title="Take the tour"
                                    icon={arrowRight}
                                    alt="Right Arrow">
                                </BaseButton>
                            </Col>
                        </Grow>
                    </Row>
                </Container>
            </article>
            <article className="personal-assistant-article">
                <Container>
                    <Row>
                        <Col md={12} lg={6}>
                            <Grow in={true} timeout={500}>
                                <div className="picture-container gift">
                                    <ScrollAnimation animateIn='animate__fadeInRight'
                                        animateOnce={true}>
                                        <DesignElement
                                            imageSrc={iconFirework}
                                            alt="Firework" />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='animate__fadeInDown'
                                        animateOnce={true}>
                                        <DesignElement
                                            imageSrc={iocnThankYou}
                                            alt="Thank You" />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='animate__fadeInLeft'
                                        animateOnce={true}>
                                        <DesignElement
                                            imageSrc={iconCake}
                                            alt="Cake" />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='animate__fadeInLeft'
                                        animateOnce={true}>
                                        <DesignElement
                                            imageSrc={iconChristmasTree}
                                            alt="Christmas Tree" />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='animate__fadeInRight'
                                        animateOnce={true} >
                                        <DesignElement
                                            imageSrc={iconGiftbox}
                                            alt="Giftbox" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                        </Col>
                        <Slide in={true} timeout={500} direction="left">
                            <Col md={12} lg={6}>
                                <h2>Your personal shopping assistant </h2>
                                <p>Think of WisePrezzie as your personal shopping assistant. Tell it what your friends and family like and don't like, and WisePrezzie personalizes gift recommendations and gets smarter and more personalized as you interact with it to help take the fuss out of managing gift giving.</p>
                            </Col>
                        </Slide>
                    </Row>
                </Container>
            </article>
            {/* TODO: Add testimonials once we receive them */}
            {/* <article className="testimonial-article">
                <Container>
                    <Row>
                        <Col>
                            <Testimonial />
                            <a href="/" className="link">See what others are saying about WisePrezzie</a>
                        </Col>
                    </Row>
                </Container>
            </article>*/}
            <article className="newsletter-article"> 
                <Container>
                    <Row>
                        <Col>
                            <Newsletter />
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    );
}

export default Home;
