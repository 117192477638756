import React, { FunctionComponent } from "react";
import './HeaderModal.scss';

type HeaderProps = {
    span: string,
    heading: string,
    paragraph: string,
}

const HeaderModal: FunctionComponent<HeaderProps> = ({ heading, span, paragraph }) => {
    return (
        <section className="header-container">
            <h1><span>{span}</span>{heading}</h1>
            <p>{paragraph}</p>
        </section>
    )
}

export default HeaderModal;