import { Container, Row } from 'react-bootstrap';
import './PaymentCancelled.scss'

const PaymentCancelled = () => {

    return (
        <Container>
            <Row >
                <div className="card-cancel">
                    <h1>Payment cancelled</h1>
                    <p>There was an error processing your payment<br /> Please try again or reach us directly.</p>
                </div>
            </Row>
        </Container >
    )
}

export default PaymentCancelled;