import React, { FunctionComponent } from "react";
import arrowRight from '../../assets/icons/right-arrow.svg';
import './SocialIcon.scss';

type SocialIconProps = {
    icon: string,
    alt: string,
    href: string,
    target: string,
    rel: string
  }

  const SocialIcon: FunctionComponent<SocialIconProps> = ({ icon, alt, href,target, rel }) => {
    return (
        <a href={href} target={target} rel={rel} className="social-icon">
            <img src={icon} alt={alt}></img>
        </a>
    )
}

export default SocialIcon;