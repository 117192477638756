import React, { FunctionComponent, useState, useEffect } from "react";
import { Col, Row, Container } from 'react-bootstrap'
import BaseImage from "../base-components/BaseImage";
import './PersonSearchResult.scss';
import avatarGrey from '../../assets/icons/avatar-grey.svg'
import { Fade, Grow, Slide } from '@material-ui/core';
import BaseButton from "../base-components/BaseButton";
import { RelationshipFormComponent } from "../../components/form-components/RelationshipFormComponent";

type PersonSearchResultProps = {
    personName: string,
    personIcon?: string,
    onClick?: any,
    personCity?: string,
    personState?: string,
    personCountry?: string,
}

const PersonSearchResult: FunctionComponent<PersonSearchResultProps> = ({ personName, personIcon, personCity, personCountry, personState, onClick }) => {

    const [avatarImage, setAvatarImage] = useState(avatarGrey);
    const [relationship, setRelationship] = useState<string>();

    let location = `${personState ? personState : ''} ${personCity ? personCity : ''} ${personCountry ? personCountry : ''}`;

    //TODO: lift the state of the images, so we don't pull each time
    function getProfileImage() {
        if (personIcon) {
            (async () => {
                let res = await fetch('api/Images/GetImage?url=' + personIcon)
                let imageUrl = await res.url;

                setAvatarImage(imageUrl);
            })();
        }
    }

    useEffect(() => {
        getProfileImage();
    }, [personIcon]);

    return (
        <Container className="person-search-result-container">
            <Fade in={true} timeout={500}>
                <Row>
                    <Col sm={12} md={6} className="person-info-container person-image-group">
                        <BaseImage
                            img={avatarImage}
                            alt={personName}
                        />
                        <section className="person-info">
                            <div>
                                <h3> {personName} </h3>
                                <p>{location}</p>
                            </div>
                        </section>
                    </Col>
                    <Col sm={12} md={6} className="options-container">
                        <section className="person-relationship">
                            <RelationshipFormComponent relationship={relationship} setRelationship={setRelationship} />
                        </section>
                        <BaseButton
                            icon=""
                            onClick={() => onClick(relationship)}
                            title="Add"
                        />
                    </Col>
                </Row>
            </Fade>
        </Container>
    )
}

export default PersonSearchResult;