import Reac, { useState, useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './YouModal.scss';
import BaseButton from '../../base-components/BaseButton';
import ToggleButtonModal from '../modal-components/ToggleButtonModal';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import AvatarModal from '../modal-components/AvatarModal';
import HeaderModal from '../modal-components/HeaderModal';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import avatarRed from '../../../assets/icons/avatar-red.svg'
import { UserContext } from '../../../context/UserContext';
import { LocationFormComponent } from '../../form-components/LocationFormComponent';
import { DatePickerFormComponent } from '../../form-components/DatePickerFormComponent';
import { IsNullOrEmpty } from "../../../utils/Utils";

const YouModal = ({ values, onNextModal }) => {
    const { user, updateUser } = useContext(UserContext);

    const [genderRequired, setGenderRequired] = useState(false)
    const [birthdayRequired, setBirthdayRequired] = useState(false)
    const [locationRequired, setLocationRequired] = useState(false)
    const [gender, setGender] = useState("");
    const [ageRange, setAgeRange] = useState("1");
    const [location, setLocation] = useState({ country: 'US', state: 'State', city: '' });
    const [date, setDate] = useState<Date>()
    const userName = values.firstName + " " + values.lastName;

    function onGenderChange(gender) {
        setGender(gender)
        setGenderRequired(false)
    }

    let onProfileClick = (isClicked) =>{
        //why? why not.
        console.log('is clicked');
    }

    function updateCurrentUser() {

        let hasError = false;

        if (gender == "") {
            setGenderRequired(true)
            hasError = true;
        }

        if (date == undefined) {
            setBirthdayRequired(true)
            hasError = true;
        }

        if (location.country == "Country") {
            setLocationRequired(true)
            hasError = true;
        }

        if (IsNullOrEmpty(location.city)) {
            setLocationRequired(true)
            hasError = true;
        }

        if(hasError){
            return;
        }

        fetch("api/Users/UpdateCurrentUser", {
            "method": "PUT",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
            },
            "body": JSON.stringify({
                userId: user.userId,
                gender: gender,
                birthday:  date ? new Date(date) : undefined,
                agerange: parseInt(ageRange),
                country: location.country,
                state: location.state,
                city: location.city
            })
        })
            .then(response => {
                if (response.ok) {

                    updateUser({
                        userId: user.userId,
                        isLoggedIn: true,
                        userName: userName,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        birthday: date,
                        ageRange: ageRange,
                        gender: gender,
                        country: location.country,
                        state: location.state,
                        city: location.city
                    });

                    console.log("updated user ", user)
                }
                else {
                    response.text().then(text => console.log(text));
                }
            })
            .then(data => console.log(data))
            .catch(err => {
                console.log(err);
            });

            onNextModal();
    }

    return (
        <section className="you-modal active">
            <ProgressLineModal />
            <HeaderModal
                heading={userName + ", fill in your personal details!"}
                span=""
                paragraph="We use this to show relevant brands and gift recommendations to you!"
            />

            <Container fluid>
                <Row>
                    <Col sm={12} lg={4} className="avatar-box">
                        <AvatarModal
                            userName={userName}
                            onProfileClick={onProfileClick}
                            alt="User image" />
                    </Col>
                    <Col sm={12} lg={8} className="avatar-info">
                        <Row>
                            <Col>
                                <h5>Gender</h5>
                                <ToggleButtonModal onGenderChange={onGenderChange} />
                                <span className="required-modal-field" hidden={!genderRequired}> Gender is required </span>
                            </Col>
                        </Row>
                        <Row>
                            <DatePickerFormComponent value={date} onChange={setDate} />
                            <span className="required-modal-field" hidden={!birthdayRequired}> Birthday is required </span>
                        </Row>
                        <Row>
                            <Col>
                                <h5><span>OR</span> Age range</h5>
                                <Row>
                                    <Col sm={12} lg={4}>
                                        <select className="DatePicker" placeholder="Age Range" onChange={(e) => setAgeRange(e.currentTarget.value)}>
                                            <option value="1">Below 21</option>
                                            <option value="2">22-30</option>
                                            <option value="3">31-40</option>
                                            <option value="4">41-50</option>
                                            <option value="5">51-60</option>
                                            <option value="6">61+</option>
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <LocationFormComponent location={location} setLocation={setLocation} />
                        <span className="required-modal-field" hidden={!locationRequired}> Location is required </span>

                        <BaseButton
                            onClick={updateCurrentUser}
                            title="Next"
                            icon={arrowRight}
                            alt="Right Arrow"
                            className="arrow-animation"
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default YouModal;