import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './AddConnectionPopUp.scss';
import BaseButton from '../../base-components/BaseButton';
import AddConnectionModal from './AddConnectionModal'


interface Props {
    show: boolean,
    onHide: any,
    addConnection: any,
}


function AddConnectionPopUp(props: Props) {

    function onNextModal(modal)
    {
       if(modal == 8)
       {
            //Decide whether to hide the initial modal with the red buttons
            // props.onHide()
       }
    }

    return (
        <Modal
            {...props}
            className="add-connection-pop-up"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddConnectionModal addConnection={(e) => props.addConnection(e)} onNextModal={(modal) => onNextModal(modal)}  />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    addConnection: any
}


const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onClick()
    {
        setModalShow(true)   
    }

    return (
        <>
            <BaseButton
                onClick={onClick}
                title={props.title}
                icon={props.icon}
                alt="">
            </BaseButton>
            <AddConnectionPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                addConnection={(e) => props.addConnection(e)}
            />
        </>
    );
}



export default App;