import { FunctionComponent } from "react";
import './PersonImage.scss';
import BaseImage from '../base-components/BaseImage';
import YesNoModal from '../../components/modal/modal-yes-no/YesNoModal';
import cancelIcon from '../../assets/icons/cancel.svg';
import { useHistory } from "react-router-dom";

type PersonImageProps = {
    personImg?: string,
    personAlt?: string,
    personIcon?: string,
    connectionToEdit?: any,
    onDelete?: any,
    onEditFinished?: any,
    initials?: string,
    goToProfile?: Function
}

const PersonImage: FunctionComponent<PersonImageProps> = ({ personImg, personAlt, personIcon, connectionToEdit, onEditFinished, onDelete, initials, goToProfile }) => {
    
    const goToConnectionProfile = () => {
        if(goToProfile){
            goToProfile();
        }
    }

    return (
        <div className="person-image-group">
            {onDelete && <YesNoModal
                modalTitle="Remove connection"
                subtitle={`Are you sure you want to delete this connection?`}
                icon={cancelIcon}
                onPositive={() => onDelete()}
            />}
            <div className="person-image-wrapper" onClick={() =>goToConnectionProfile()}>
                {personImg && <img src={personImg} alt={personAlt} className="avatar" ></img>}
                {!personImg && <div className="initials-view">{!initials ? "?" : initials}</div>}
            </div>
            <BaseImage
                img={personIcon}
                alt="Avatar icon"
                onClick={goToProfile}
            />
        </div>
    )
}

export default PersonImage;
