import React from 'react'
import './TermsConditions.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../components/footer/Footer';

const TermsConditions = () => {
    return (
        <>
            <article className="policy-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>WisePrezzie Terms & Conditions</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Effective date: August 5, 2020</p>
                            <h2>Welcome to WisePrezzie!</h2>
                            <p>These Terms of Use are a binding contract between you and WisePrezzie Inc. (“we”, “us”, “our”). It contains the rules and restrictions that govern your use of WisePrezzie’s products and services (referred to below simply as “WisePrezzie”). If you have any questions, comments, or concerns regarding these Terms or our products and services, please contact us at tos@WisePrezzie.
                                <br />
                                <br />
                                Using WisePrezzie in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use WisePrezzie. These Terms include everything in this document, as well as those in the Privacy Policy, Publisher Agreement, Content Guidelines, and Copyright Dispute Policy. If you don’t agree to all of the following, you may not use or access WisePrezzie in any manner.
                                <br />
                                <br />
                                You represent and warrant that you are of legal age to form a binding contract (or if not, that you’ve received your parent’s or guardian’s permission to use WisePrezzie and that your parent or guardian agrees to these Terms on your behalf).
                                If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” throughout this document refer to that organization or entity).</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Creating an Account on WisePrezzie</h2>
                            <p>You may be required to sign up for an account and select a password. You promise to provide us with accurate, complete, and up-to-date registration information about yourself.
                                <br />
                                <br />
                                You agree that you will only use WisePrezzie for your own personal or organizational use, and not on behalf of or for the benefit of any third party. You may not transfer your account to anyone else without our prior written permission.
                                <br />
                                <br />
                                You may not select as your WisePrezzie account name a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. WisePrezzie reserves the right to refuse registration of or cancel a WisePrezzie account name at its discretion. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Posting Content on WisePrezzie</h2>
                            <p>First and foremost, you own what you create. Any original content you post, upload, share, store, or otherwise provide to WisePrezzie remains yours and is protected by copyright and any other applicable intellectual property laws.
                                <br />
                                <br />
                                That includes newsletters, subscriber lists, any other text or photos you upload to your subdomain on WisePrezzie, and any information that you provide to obtain a WisePrezzie username and account. It also includes any comments posted on any current or future discussion board features on WisePrezzie. </p>
                            <p>Anything posted, uploaded, shared, stored, or otherwise provided through WisePrezzie is referred to as a “Post” in these Terms. There are a few rules that apply to all Posts:</p>
                            <ul>
                                <li>Don’t Infringe: Your Posts should not violate someone else’s (including WisePrezzie’s) rights. Don’t copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any content not owned by you unless you have prior consent from the owner of that content. </li>
                                <li>Limited License to Us: In order to display your Posts on WisePrezzie, and to allow other users to enjoy them, you grant us certain rights in your Posts:</li>
                                <li>Operating WisePrezzie: You hereby grant WisePrezzie a license to translate, modify, reproduce, and otherwise act with respect to your Posts to enable us to provide, improve, and notify you about new features within WisePrezzie. You understand and agree that we may need to make changes to your Posts to conform and adapt those Posts to the technical requirements of networks, devices, services, or media, and this license includes the rights to do so. For example, we may need to modify your newsletter to make sure it is viewable on an iPhone as well as a computer. </li>
                                <li>Public Posts: If you share a Post with other users on WisePrezzie, then you grant us the license above, as well as a license to display, perform, and distribute your Post. Also, you grant all other users of WisePrezzie a license to access the Post, and to use and exercise all rights in it, as permitted by the functionality of WisePrezzie. </li>
                                <li>Term of License: You agree that the licenses you grant are royalty-free, perpetual, irrevocable, and worldwide. This is a license only – your ownership in Posts is not affected.</li>
                            </ul>
                            <p>We reserve the right to remove any content from WisePrezzie at any time, for any reason (including, but not limited to, if someone alleges you contributed that content in violation of these Terms), in our sole discretion, and without notice.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Intellectual Property and Reporting Infringement</h2>
                            <p>We respect others’ intellectual property rights, and we reserve the right to delete or disable content alleged to be infringing, and to terminate the accounts of repeat alleged infringers. You promise to abide by copyright notices, trademark rules, information, and other restrictions you may receive from us or that are posted on WisePrezzie.
                                <br />
                                <br />
                                To review our complete Copyright Dispute Policy and learn how to report potentially infringing content, click here. To learn more about the Digital Millennium Copyright Act, which governs how we handle these reports, click here.
                                <br />
                                <br />
                                You understand that we own WisePrezzie. These Terms don’t grant you any right, title or interest in WisePrezzie, or our trademarks, logos, and other intellectual property. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Acceptable Use Policy</h2>
                            <p>You are responsible for all your activity in connection with WisePrezzie! Make sure that you use WisePrezzie in a manner that complies with the law. If your use of WisePrezzie is prohibited by applicable laws, then you aren’t authorized to use WisePrezzie. We can’t and won’t be responsible for you using WisePrezzie in a way that breaks the law.</p>
                            <p>You also agree that you will not contribute any Post or otherwise use WisePrezzie in a manner that:</p>
                            <ul>
                                <li>Is fraudulent or threatening, or in any way violates WisePrezzie’s Content Guidelines;</li>
                                <li>Jeopardizes the security of your WisePrezzie account or anyone else’s (such as allowing someone else to log into WisePrezzie as you, or sharing your account or password with someone);</li>
                                <li>Attempts, in any manner, to obtain the password, account, or other security information of any other user;</li>
                                <li>Violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                                <li>Runs Maillist, Listserv, any form of auto-responder or “spam” on WisePrezzie, or any processes that run or are activated while you are not logged into WisePrezzie, or that otherwise interferes with the proper working of WisePrezzie (including placing an unreasonable load on WisePrezzie’s infrastructure);</li>
                                <li>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of WisePrezzie (through use of manual or automated means);</li>
                                <li>Copies or stores any significant portion of the content on WisePrezzie;</li>
                                <li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to WisePrezzie.</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Other Users and Third-Parties</h2>
                            <ul>
                                <li>Posts: Posts posted to WisePrezzie – including Publisher newsletters – are the sole responsibility of the person or organization from whom such content originated. You access all such content at your own risk. We aren’t liable for any errors or omissions in any Post and you hereby release us from any damages or loss you might suffer in connection with a Post.</li>
                                <li>Other Users on WisePrezzie: Your interactions with organizations and individuals found on or through WisePrezzie, including payment for Publisher newsletters, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and individuals. You agree that WisePrezzie shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings. We can’t guarantee the identity of any users with access to WisePrezzie and are not responsible for which users gain access to our products and services.</li>
                                <li>Third-Party Content: WisePrezzie may contain links or connections to third party websites or services that are not owned or controlled by us. WisePrezzie has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites. You release and hold us harmless from any and all liability arising from your use of any third-party website or service.</li>
                            </ul>
                            <p>In the event that you have a dispute with one or more other users of WisePrezzie or with a third party, you release us, our officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or WisePrezzie. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Paid Subscriptions on WisePrezzie</h2>
                            <p>If you publish newsletters through WisePrezzie, you are a Publisher. If you subscribe to Publisher newsletters, you are a Reader. Readers subscribe to newsletters directly through the Publisher’s subdomain on WisePrezzie.
                                <br />
                                <br />
                                A Publisher may offer their newsletters for free or for a subscription fee, to be determined in the Publisher’s discretion. Readers may choose to subscribe to Publisher newsletters on WisePrezzie and agree to incur any applicable subscription fees.
                                <br />
                                <br />
                                Publishers will set prices for their newsletters, and may change the prices at their sole discretion through their Publisher account, though no price changes shall apply retroactively.
                                <br />
                                <br />
                                In the event that a Reader has a dispute with a Publisher, you agree that WisePrezzie is under no obligation to become involved other than to direct any inquiries regarding a Publisher’s newsletter to the appropriate Publisher pursuant to the Publisher Agreement. To learn more about how WisePrezzie manages its relationships with Publishers, you should check out the Publisher Agreement.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Terminating Your Account</h2>
                            <p>WisePrezzie is free to terminate (or suspend access to) your use of WisePrezzie, or your account, for any reason at our discretion. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important Posts you may have uploaded to your account, but we may not do so if we determine it would be impractical, illegal, not in the interest of someone’s safety or security, or otherwise harmful to the rights or property of WisePrezzie.
                                <br />
                                <br />
                                WisePrezzie also allows you to delete your account at any time. When you delete your account, any Posts associated with that account will also be deleted. However, any Post that you have made public may remain available.
                                <br />
                                <br />
                                You understand and agree that it may not be possible to completely delete your content from WisePrezzie’s records or backups, and that your Posts may remain viewable elsewhere to the extent that they were copied or stored by other users. Please refer to our Privacy Policy to understand how we treat information you provide to us after you have stopped using WisePrezzie.
                                <br />
                                <br />
                                You agree that some of the obligations in these Terms will be in force even after you terminate your account. All of the following terms will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, terms regarding disputes between us, and any other terms that, by their nature, should survive termination of these Terms.
                                <br />
                                <br />
                                If you have deleted your account by mistake, contact us immediately at tos@WisePrezzie – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Privacy on WisePrezzie</h2>
                            <p>WisePrezzie takes your privacy very seriously. For the current WisePrezzie Privacy Policy, please click here.
                                <br />
                                <br />
                                The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13.  We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for WisePrezzie or send any personal information about yourself to us.  If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible.  If you believe that a child under 13 may have provided us personal information, please contact us at tos@WisePrezzie.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Changes to WisePrezzie</h2>
                            <p>We’re always trying to improve WisePrezzie, so our products and services may change over time. We may suspend or discontinue any part of WisePrezzie, or we may introduce new features or impose limits on certain features or restrict access to parts or all of WisePrezzie. We’ll try to give you notice when we make a material change to WisePrezzie that would adversely affect you, but this isn’t always possible or practical.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Changes to the Terms</h2>
                            <p>We are constantly trying to improve our products and services, so these Terms may need to change along with WisePrezzie.  We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the website, by sending you an email, and/or by some other means.
                                <br />
                                <br />
                                If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use WisePrezzie.  If you use WisePrezzie in any way after a change to the Terms is effective and notice has been provided, that means you agree to all of the changes.
                                <br />
                                <br />
                                Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Violations of the Terms</h2>
                            <p>Failure to follow any of these Terms shall constitute a breach of these Terms, which may result in immediate termination of your account. WisePrezzie has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Miscellaneous Terms</h2>
                            <p>The above covers most of the questions that we typically receive about WisePrezzie. We have grouped provisions that come up less frequently below:</p>
                            <ul>
                                <li>No Warranties: WisePrezzie is provided to you on an “as-is” basis. This means we provide it to you without any express or implied warranties of any kind. That includes any implied warranties of merchantability, warranties of fitness for a particular purpose, non-infringement, or any warranty that the use of WisePrezzie will be uninterrupted or error-free. Accordingly, we do not:</li>
                                <li>Make any representations or warranties about any content contained in or accessed through WisePrezzie, and we will not be responsible for the accuracy, copyright compliance, legality, or decency of material contained on our products and services. </li>
                                <li>Make any representations or warranties regarding suggestions or recommendations of products or services (including Publisher newsletters) offered or purchased through WisePrezzie. Products and services purchased or offered through WisePrezzie, including newsletters, are provided “as-is” and without any warranty of any kind from WisePrezzie. </li>
                                <li>Limitation of Liability: To the fullest extent allowed by applicable law, under no circumstances and under no legal theory shall WisePrezzie, its licensors, or its suppliers be liable to you or to any other person for:</li>
                                <li>Any indirect, special, incidental, or consequential damages of any kind, or</li>
                                <li>Any amount, in the aggregate, in excess of the greater of (1) $100 or (2) the amounts paid and/or payable by you to us in connection with WisePrezzie in the twelve-month period preceding the applicable claim. </li>
                                <li>Indemnification: To the fullest extent allowed by applicable law, you agree to indemnify and hold WisePrezzie, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of WisePrezzie (including any actions taken by a third party using your account), and (b) your violation of these Terms.  In the event of such a claim, suit, or action, we will attempt to provide notice to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</li>
                                <li>Assignment: You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your WisePrezzie account, in any way (by operation of law or otherwise) without our prior written consent.  We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</li>
                                <li>Choice of Law: These Terms are governed by and will be construed under applicable federal law and the laws of the State of California, without regard to the conflicts of laws provisions thereof.</li>
                                <li>Arbitration: Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in San Francisco County, California, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. (“JAMS”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Streamlined Arbitration Rules and Procedures of JAMS. Judgment upon the award so rendered may be entered in a court having jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, you and WisePrezzie shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.  For all purposes of these Terms, you consent to exclusive jurisdiction and venue in the state or federal courts located in, respectively, San Francisco County, California, or the Northern District of California. The prevailing party in any action or proceeding arising out of these Terms will be entitled to an award of costs and attorneys’ fees.</li>
                                <li>No Third-Party Beneficiaries: We agree there are no third-party beneficiaries intended under these Terms.</li>
                                <li>No Joint Venture: You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of WisePrezzie Inc., and you do not have any authority of any kind to bind us in any respect whatsoever. </li>
                                <li>Waiver: The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. </li>
                                <li>Severability: If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable.  </li>
                                <li>Entire Agreement: You agree that these Terms are the complete and exclusive statement of the mutual understanding between you and us, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default TermsConditions;