import React, { FunctionComponent, useState, useContext } from "react";
import './ProfileDropdown.scss';
import BaseImage from "../base-components/BaseImage";
import avatarWhite from '../../assets/icons/avatar-white.svg'
import userIcon from '../../assets/svg/user-profile.svg';
import logoutIcon from '../../assets/svg/logout.svg';
import { useHistory } from "react-router-dom";
import { NavDropdown } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext'
import { Link } from 'react-router-dom';
import settingsIcon from '../../assets/svg/settings.svg';
import aboutIcon from '../../assets/svg/about.svg';

const ProfileDropdown = () => {

    const history = useHistory();
    const { user, updateUser } = useContext(UserContext);

    const logout = () => {
        fetch('api/Auth/Logout')
            .then(response => {
                history.push('/');
                updateUser({
                    userName: '',
                    userId: '',
                    isLoggedIn: false
                });
            })
    }
    //TODO: Confirm it does not crash
    const initials = user.userName?.split(" ").map((n) => n[0]).join("")

    return (
        <NavDropdown className="profile-login" title={
            <div className="profile-avatar">
                {user.profilePhotoDownloadUrl && <img src={user.profilePhotoDownloadUrl} alt="User" className="avatar"></img>}
                {!user.profilePhotoDownloadUrl && <div className="initials-view navbar-initilas">{!initials ? "?" : initials}</div>}
                <p>{user.userName}</p>
            </div>} id="collasible-nav-dropdown">
            <NavDropdown.Item>
                <BaseImage
                    img={userIcon}
                    alt="Profile" />
                <Link to={'/relationships'}>Account</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <BaseImage
                    img={settingsIcon}
                    alt="Settings" />
                <Link to={'/settings'}>Settings</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <BaseImage
                    img={aboutIcon}
                    alt="About" />
                <Link to={'/about'}>About</Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout}>
                <BaseImage
                    img={logoutIcon}
                    alt="Lougout" />
                <span>Logout</span>
            </NavDropdown.Item>
        </NavDropdown>
    );
}

export default ProfileDropdown;