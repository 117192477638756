export function IsNullOrEmpty(string)
{
    if(string == "" || string == null || string == undefined)
    {
        return true;
    }

    return false
}

export const publicHolidaysKvP =
[
    {  value: "New Year's Eve", key: "NewYearsDay" },
    {  value: "Valentine's Day", key: "ValentinesDay" },
    {  value: "Easter Sunday", key: "Easter" },
    {  value: "Mother's Day", key: "MothersDay" },
    {  value: "Father's Day", key: "FathersDay" },
    {  value: "Independence Day", key: "IndependenceDay" },
    {  value: "Sweetest Day", key: "SweetestDay" },
    {  value: "Thanksgiving Day", key: "Thanksgiving" },
    {  value: "Hanukkah", key: "Hannukah" },
    {  value: "Christmas Day", key: "Christmas" },
    {  value: "Kwanzaa", key: "Kwanzaa" },
    {  value: "Birthday", key: "Birthday" },
    {  value: "Anniversary", key: "Anniversary" },
    {  value: "Just Because", key: "JustBecause"},
]

export function getHolidayName(type)
{
    return publicHolidaysKvP.find(hol => hol.key == type)?.value
}


export function saturdaysInMonth( m, y ) {
    var days = new Date( y,m,0 ).getDate();
    var saturdays = [ 7 - (new Date( m +'/01/'+ y ).getDay()) ];
    for ( var i = saturdays[0] + 7; i < days; i += 7 ) {
      saturdays.push( i );
    }
    return saturdays;
}

export function sweetsDayDate()
{
    var date = new Date()
    date.setMonth(9)
    date.setDate(saturdaysInMonth(10, new Date().getFullYear())[2])
    if (new Date() > date) {
        date.setFullYear(date.getFullYear() + 1)
        date.setDate(saturdaysInMonth(10, new Date().getFullYear() + 1)[2])

    }

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date 
}