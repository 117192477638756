import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FeedbackModal.scss';
import HeaderModal from '../modal-components/HeaderModal';
import BaseImage from "../../base-components/BaseImage";
import BaseTextarea from "../../base-components/BaseTextarea";
import { feedbackStatus } from './feedbackStatus';

const FeedbackModal = (props) => {
    const [active, setActive] = useState('');
    const [isError, setIsError] = useState(false);

    const handleToggle = (status) => {
        props.onFeedbackChange(prevState => ({ ...prevState, feedbackStatus: status }));
        setActive(status);
    }; 

    const onTextChange = (text: string) => {
        if (text.length > 2000) {
            setIsError(true);
        }
        else {
            if (isError) {
                setIsError(false);
            }
            props.onFeedbackChange(prevState => ({ ...prevState, text: text }));
        }
    }

    return (
        <section className="feedback-modal">
            <HeaderModal
                heading=" What do you think of Wiseprezzie"
                span=""
                paragraph="Your feedback is important to us"
            />

            <Container fluid>
                <Row>
                    <Col sm={12} className="feedback-rating">
                        {Object.entries(feedbackStatus).map(([status, pic]) => (
                            <div className={active == status ? "selected" : ""}>
                                <BaseImage
                                    img={pic}
                                    onClick={() => handleToggle(status)}
                                />
                            </div>
                        ))}
                    </Col>
                    {active
                        ? <Col sm={12} >
                            <h6>Do you have any thoughts you`d like to share?</h6>
                            <BaseTextarea
                                placeholder="There`s..."
                                id=""
                                rows={5}
                                name=""
                                onTextChange={onTextChange}
                            />
                            {isError
                                ? <span>Too many characters. Limit at 2000.</span>
                                : null}
                        </Col>
                        : null}
                </Row>
            </Container>
        </section>
    )
}

export default FeedbackModal;