import React, { FunctionComponent, useRef} from "react";
import './SettingsTab.scss';
import BaseImage from '../../../components/base-image/BaseImage'


type ImageProps = {
    img: string,
    alt?: string,
    label: string
  }

  const SettingsTab: FunctionComponent<ImageProps> = ({ img, alt, label }) => {

    return (
        <>
          <BaseImage 
            img={img}
            alt={alt}
            />
          <span>{label}</span>
        </>
    )
}

export default SettingsTab;