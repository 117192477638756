import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../components/footer/Footer';
import './CookiesPolicy.scss'

const CookiesPolicy = () => {
    return (
        <>
            <article className="policy-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>WisePrezzie Cookies Policy</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2></h2>
                            <p></p>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default CookiesPolicy;