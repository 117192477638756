import React, { FunctionComponent, useEffect, useContext } from "react";
import "./GoogleFbLoginMissingDataPopUp.scss";
import { Modal, Button } from 'react-bootstrap';
import BaseButton from '../../base-components/BaseButton';
import GoogleFbLoginMissingDataModal from "./GoogleFbLoginMissingDataModal"
import { UserContext } from '../../../context/UserContext';
import { IsNullOrEmpty } from '../../../utils/Utils'

interface Props {
    show: boolean,
    onHide: any,
    onClick?: any
    onReady?: any,
    connectionId?: number,
}

function GoogleFbLoginMissingDataPopUp(props: Props) {

    return (
        <Modal
            {...props}
            className="google-fb-missing-data-popup"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <GoogleFbLoginMissingDataModal onHide={() => props.onHide()} connectionId={props.connectionId} onReady={() => props.onReady()} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    onClick?: any,
    show?: boolean,
    onReady?: any,
    connectionId?: number,
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const { user, updateUser } = useContext(UserContext);

    useEffect(() => 
    {
        if(props.show)
        {
            console.log("GENDER", user.gender)
            setModalShow(true)
        }
    }, [props.show]);


    return (
        <>
            <GoogleFbLoginMissingDataPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                onReady={() => props.onReady()}
                connectionId={props.connectionId}
            />
        </>
    );
}

export default App;