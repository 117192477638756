import { BrowserRouter } from "react-router-dom";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './routes/Routes';
import UserProvider from './context/UserContext';
import AuthenticateContext from './context/AuthenticateContext';
import TagManager from 'react-gtm-module'
import { useEffect } from "react";

function App() {
 
  const tagManagerArgs = {
    gtmId: 'GTM-MQ3VX6X'
  }

  TagManager.initialize(tagManagerArgs)

  return (
    
    <UserProvider>
      <BrowserRouter>
        <AuthenticateContext />
        <Routes />
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
