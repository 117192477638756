import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './PopUp.scss';
import BaseButton from '../base-components/BaseButton';
import TryModal from './modal-try/TryModal';
import YouModal from './modal-you/YouModal';
import ProfileCreatedModal from './modal-profile-created/ProfileCreatedModal';
import { IsNullOrEmpty } from '../../utils/Utils';

interface Props {
    show: boolean,
    onHide: any,
    onShow: any,
    values: object,
}

function PopUp(props: Props) {
    const [userName, setUserName] = useState("")
    const [activeModal, setActiveModal] = useState(0);

    function onNextModal() {
        setTimeout(() => {
            setActiveModal(activeModal + 1);
        }, 150)
    }

    function onHide() {
        props?.onHide();
        setActiveModal(0);
    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {activeModal == 0 && <TryModal
                    userValues={props.values} onNextModal={onNextModal} />}
                {activeModal == 1 && <YouModal
                    values={props.values} onNextModal={onNextModal} />}
                {activeModal == 2 && <ProfileCreatedModal
                    user={{ userName: userName }} onHide={onHide} />}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    disabled,
    values: object,
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [isInInviteStep, setIsInInviteStep] = useState(false)

    function onClick() {
        console.log(props)
        if (props.disabled) {
            setModalShow(true)
        }
    }

    useEffect(() => {
        var url = new URL(window.location.toString())
        if (url.toString().includes("invite-connection")) {

            let email = url.searchParams.get("invited-by")

            if (!IsNullOrEmpty(email)) {
                setIsInInviteStep(true)
            }
        }
    }, []);

    return (
        <>
            <BaseButton
                onClick={onClick}
                title={props.title}
                icon={props.icon}
                alt="Right Arrow"
                className={isInInviteStep ? "arrow-animation pulsing-button" : "arrow-animation"}>
            </BaseButton>
            <PopUp
                show={modalShow}
                values={props.values}
                onHide={() => setModalShow(false)}
                onShow={() => setModalShow(true)}
            />
        </>
    );
}



export default App;