import React, { FunctionComponent } from "react";
import './ProgressLineModal.scss';
import checkIcon from '../../../assets/icons/checking-mark.svg'


const ProgressLineModal = () => {
    return (
        <section className="progress-line">
            <div className="progress-point">
                <img src={checkIcon} alt="Checking mark"></img>
                <span>Skip</span>
            </div>
            <div className="progress-point">
                <img src={checkIcon} alt="Checking mark"></img>
                <span>Skip</span>
            </div>
        </section>
    )
}

export default ProgressLineModal;