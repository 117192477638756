import React, { FunctionComponent, useEffect, useState } from "react";
import './BaseRadio.scss';

type RadioProps = {
    radioId: string,
    label: string,
    isActive?: boolean
    onClick?: any,
    radioActive?: { activeId: string, setActiveId: any }
}

const BaseRadio: FunctionComponent<RadioProps> = ({ radioId, label, onClick, radioActive }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (radioActive?.activeId == radioId) {
            setIsActive(true)
        }
        else{
            setIsActive(false)
        }
    }, [radioActive])

    const handleToggle = () => {
        if (!isActive) {
            radioActive?.setActiveId(radioId)
            onClick(radioId, label)
        }
    };
    return (
        <div className={`radio-group ${isActive ? "active" : ""}`}>
            <input type="radio" id={radioId} name="radioEvents" value={radioId}></input>
            <div onClick={handleToggle} className="radio-button"></div>
            <label htmlFor={radioId}>{label}</label>
        </div>
    )
}

export default BaseRadio;