import React, { FunctionComponent, useEffect, useState } from "react";
import './EditProfileComponent';
import EditProfileModal from '../modal/modal-edit-profile/EditProfileModal';
import BaseImage from '../base-components/BaseImage';
import personIcon from '../../assets/icons/user-icon.svg'
import { IUser, IUserConnection } from "../../types/types-internal";

type EditProfileComponentProps = {
    profile?: IUser | IUserConnection,
    updateProfile?: any,
}

const EditProfileComponent: FunctionComponent<EditProfileComponentProps> = (props) => {

    const [modalShow, setModalShow] = useState<boolean>(false);

    function onClick(){
        setModalShow(true)
    }

    return (
        <>
           <BaseImage
                onClick={onClick}
                img={personIcon}
                alt="Avatar icon"
            />
            
            <EditProfileModal
                profile = {props.profile}
                updateProfile = {(profile, image) => props.updateProfile(profile, image)}
                showModal = {modalShow}
                setShowModal = {setModalShow}
            />
        </>
    );
}

export default EditProfileComponent;