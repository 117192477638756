import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './Newsletter.scss';
import BaseButton from '../base-components/BaseButton';
import logo from '../../assets/logo/wise-prezzie-logo-white.svg';
import arrowRight from '../../assets/icons/right-arrow.svg';
import Modal from '../modal/PopUp';
import { IsNullOrEmpty } from "../../utils/Utils";

const Newsletter = () => {

    const initialValues = { email: "", password: "", firstName: "", lastName: "", isRealUser: true };

    const [values, setValues] = React.useState(initialValues);
    
    const [touched, setTouched] = React.useState<any>({});
    const [errors, setErrors] = React.useState<any>({});

    // change event handler
    const handleChange = evt => {
        const { name, value: newValue, type } = evt.target;

        // keep number fields as numbers
        const value = type === "number" ? +newValue : newValue;

        // save field values
        setValues({
            ...values,
            [name]: value
        });

        // was the field modified
        setTouched({
            ...touched,
            [name]: true
        });

        handleBlur(evt)
    };


    const nameValidation = (fieldName, fieldValue) => {
        if (fieldValue.trim() === "") {
            return `First name is required`;
        }

        return null;
    };

    const emailValidation = email => {
        if (
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            )
        ) {
            return null;
        }
        if (email.trim() === "") {
            return "Email is required";
        }
        return "Please enter a valid email";
    };

    const passValidation = sth => {
        return true;
    }

    const validate = {
        firstName: name => nameValidation(values.firstName, name),
        lastName: name => nameValidation(values.lastName, name),
        email: emailValidation,
        age: passValidation,
        password: passValidation,
        isRealUser: passValidation,
    };

    function validateValues() 
    {
       if(values.firstName === "" || values.email === "")
       {
            return false
       }
       else
       {
            if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    values.email
                )
            ) {
                return false
            }
           
           return true
       }
    };

    const handleBlur = evt => {
        const { name, value } = evt.target;

        // remove whatever error was there previously
        const { [name]: removedError, ...rest } = errors;

        // check for a new error
        const error = validate[name](value);

        // // validate the field if the value has been touched
        setErrors({
            ...rest,
            ...(error && { [name]: touched[name] && error })
        });
    };

    const handleSubmit = evt => {
        evt.preventDefault();

        const formValidation = Object.keys(values).reduce(
            (acc, key) => {
               
                const newError = validate[key](values[key]);
                const newTouched = { [key]: true };
                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && { [key]: newError })
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched
                    }
                };
            },
            {
                errors: { ...errors },
                touched: { ...touched }
            }
        );
        setErrors(formValidation.errors);
        setTouched(formValidation.touched);

        if (
            !Object.values(formValidation.errors).length && // errors object is empty
            Object.values(formValidation.touched).length ===
            Object.values(values).length && // all fields were touched
            Object.values(formValidation.touched).every(t => t === true) // every touched field is true
        ) {
            alert(JSON.stringify(values, null, 2));
        }

        // if(IsNullOrEmpty(values.firstName)){
        //     console.log("VALUES ", values);

        //     setErrors({...errors, firstName: "First name is required"})
        // }
    };
    
    return (
        <section className="newsletter">
            <img
                className="logo"
                src={logo}
                alt="Wise Prezzie Logo"
            />
            <p>We are on a mission to provide you with the best tools to help you find impactful gifts that your family, friends, colleagues, and clients won't forget.</p>
            <form onSubmit={handleSubmit}>
                <Container className="form-container">
                    <Row>
                        <Col sm={12} md={6}>
                                <label className="error-message-text-red">
                                    <input 
                                        inputMode="text"
                                        type="text" 
                                        name="firstName"
                                        placeholder="First name"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required />  
                                </label>
                                <span>{touched.firstName && errors.firstName}</span>
                        </Col>
                        <Col sm={12} md={6}>
                                <label className="error-message-text-red">
                                    <input 
                                        type="email" 
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Email Address"
                                        required
                                    />
                                </label>
                                <span>{touched.email && errors.email}</span>
                        </Col>
                        <Col className="submit">
                            <Modal
                                title="Give Wiseprezzie a Try"
                                icon={arrowRight}
                                values={values} 
                                disabled={validateValues()}
                            /> 
                        </Col>
                    </Row>
                </Container>
            </form>
        </section>
    )
}

export default Newsletter;