export const State =
{
   "Alabama": "AL",
   "Alaska": "AK",
   "Arkansas": "AR",
   "Arizona": "AZ",
   "California": "CA",
   "Colorado": "CO",
   "Connecticut": "CT",
   "District of Columbia": "DC",
   "Delaware": "DE",
   "Florida": "FL",
   "Georgia": "GA",
   "Hawaii": "HI",
   "Iowa": "IA",
   "Idaho": "ID",
   "Illinois": "IL",
   "Indiana": "IN",
   "Kansas": "KS",
   "Kentucky": "KY",
   "Louisiana": "LA",
   "Massachusetts": "MA",
   "Maryland": "MD",
   "Maine": "ME",
   "Michigan": "MI",
   "Minnesota": "MN",
   "Missouri": "MO",
   "Mississippi": "MS",
   "Montana": "MT",
   "North Carolina": "NC",
   "North Dakota": "ND",
   "Nebraska": "NE",
   "New Hampshire": "NH",
   "New Jersey": "NJ",
   "New Mexico": "NM",
   "Nevada": "NV",
   "New York": "NY",
   "Oklahoma": "OK",
   "Ohio": "OH",
   "Oregon": "OR",
   "Pennsylvania": "PA",
   "Rhode Island": "RI",
   "South Carolina": "SC",
   "South Dakota": "SD",
   "Tennessee": "TN",
   "Texas": "TX",
   "Utah": "UT",
   "Virginia": "VA",
   "Vermont": "VT",
   "Washington": "WA",
   "Wisconsin": "WI",
   "West Virginia": "WV",
   "Wyoming": "WY",

    //Territories
   "Puerto Rico": "PR",
   "American Samoa": "AS",
   "Guam": "GU",
   "Northern Mariana Islands": "MP",
   "U.S. Virgin Islands": "VI",
}