import React, { useState, useEffect } from 'react'
import './UserConnectionPage.scss'
import AdminNavbar from "../../components/navbar/AdminNavbar"
import { Col, Container, Row } from 'react-bootstrap'
import ConnectionProfileContainer from '../../components/profile-components/ConnectionProfileContainer'
import OccasionComponent from '../../components/occasion-components/OccasionComponent';
import InterestComponent from '../../components/interest-component/InterestComponent';
import { IUserOccasion, IUserInterest, IUserConnection } from '../../types/types-internal';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Fade, Grow, Slide } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { Gender } from '../../enums/Gender'

const UserConnectionPage = (props) => {

    const [showProgressBar, setShowProgressBar] = useState(true)
    const history = useHistory()
    const { state } = useLocation<IUserOccasion>();
    const [connection, setConnection] = useState<IUserConnection>({});
    const [occasions, setOccasions] = useState<IUserOccasion[]>([])
    const [selectedOccasion, setSelectedOccasion] = useState<IUserOccasion | undefined>(state);
    const [interests, setInterests] = useState<IUserInterest[]>([])
    const [userConnectionId, setUserConnectionId] = useState(0)
    const [editedOccasions, setEditedOccasions] = useState(false)

    useEffect(() => {
        var url = new URL(window.location.toString())
        let id = Number(url.searchParams.get("id"));
        (async () => await getSelectedConnection(id))();
        setUserConnectionId(id)
        getUserConnectionOccasions(id)
        getUserConnectionInterests(id)
    }, [])

    async function getSelectedConnection(connectionId) {
        let response = await fetch(`api/Users/GetSpecificUserConnection?connectionId=${connectionId}`)
        if (response.status == 400) {
            history.push("/404")
        }

        let data = await response.json();
        let imageResp = await ('api/Images/GetImage?url=' + data.imagePathUrl);
        if (imageResp.includes('null') || imageResp.includes('undefined')) {
            data.profilePhotoDownloadUrl = null;
        }
        else {
            data.profilePhotoDownloadUrl = imageResp;
        }
        if(!data.gender){
            data.gender = Gender.NONBINARY
        }
        setConnection(data);
    }

    const updateConnection = (updatedConnection, updatedProfilePicture) => {
        (async () => {
            const settings = {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedConnection)
            };
            let respData = await fetch('api/Users/UpdateUserConnection', settings);

            //If there is an image, update profile image
            if (updatedProfilePicture) {
                const imageSettings = {
                    method: 'POST',
                    headers: {
                        Accept: 'multipart/form-data'
                    },
                    body: updatedProfilePicture
                };

                let imageData = await fetch(`api/Images/ChangePlaceholderProfileImage?connectionId=${connection.connectionId}`, imageSettings);
                let imageUrl = await imageData.text();
                updatedConnection.imagePathUrl = imageUrl;

                let downloadableImageData = await fetch('api/Images/GetImage?url=' + imageUrl)
                updatedConnection.profilePhotoDownloadUrl = downloadableImageData.url;
            }
            setConnection(updatedConnection);
        })();
    }


    async function getUserConnectionOccasions(connectionId) {
        try {
            const userConnectionOccasionsResponse = await fetch('api/Occasions/GetUserConnectionOccasions?userConnectionId=' + connectionId)
            var userConnectionOccasions = await userConnectionOccasionsResponse.json()
            setOccasions(userConnectionOccasions);
            if (!selectedOccasion) {
                setSelectedOccasion(userConnectionOccasions[0])
            }
        } catch (error) {

        }
        finally {
            return null;
        }
    }

    async function getUserConnectionInterests(connectionId) {
        try {
            const userConnectionInterestsResponse = await fetch('api/Interests/GetUserConnectionInterests?connectionId=' + connectionId)
            let userConnectionInterests = await userConnectionInterestsResponse.json()
            userConnectionInterests.sort((a, b) => a.interestId > b.interestId) //TODO: fix this sorting
            setInterests(userConnectionInterests);

            setTimeout(() => {
                setShowProgressBar(false)
            }, 500)
        } catch (error) {

        }
        finally {
            return null;
        }
    }

    function onNewInterests(e) {
        console.log("onNewInterests", e)
        setInterests(e)
    }

    function onNewOccasions(e) {
        setOccasions([...occasions, e])
        setSelectedOccasion(e);
    }

    function deleteOccasion(occasion: IUserOccasion) {
        (async () => {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: occasion["budget"],
                    typeOfOccasion: occasion["typeOfOccasion"],
                    connectionId: userConnectionId,
                    occasionId: occasion["occasionId"],
                    ocassionDate: occasion["date"]
                })
            };
            let respData = await fetch('api/Occasions/RemoveUserConnectionOccasion', settings);
            setOccasions(occasions.filter(x => x != occasion));
        })();
        if(occasion.typeOfOccasion == selectedOccasion?.typeOfOccasion){
            if(occasions.filter(x => x != occasion).length > 0){
                setSelectedOccasion(occasions[0]);
            }
            else{
                setSelectedOccasion(undefined);
            }
        }
    }

    function deleteInterest(interest) {
        (async () => {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    interestCategory: interest["interestCategory"]?.replace(" ", ""),
                    connectionId: userConnectionId,
                    interestName: interest["interestName"],
                    interestId: interest["interestId"]
                })
            };
            let respData = await fetch('api/Interests/RemoveUserConnectionInterest', settings);
            setInterests(interests.filter(int => int.interestName != interest["interestName"]))
        })();
    }

    function editOccasionBudget(occasion, budget, date) {
        (async () => {
            if (budget < 100) {
                budget = 100
            }
            const settings = {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: budget,
                    typeOfOccasion: occasion.typeOfOccasion,
                    occasionId: occasion.occasionId,
                    connectionId: userConnectionId,
                    ocassionDate: new Date(date)
                })
            };
            await fetch('api/Occasions/UpdateUserConnectionOccasion', settings);
            setEditedOccasions(true)
            getUserConnectionOccasions(connection.connectionId)
            setSelectedOccasion({ ...occasion, ocassionDate: date, budget: budget });
        })();
    }

    return (
        <>

            <div hidden={!showProgressBar}>
                <LinearProgress />
            </div>

            {!showProgressBar &&
                <>
                    <AdminNavbar />
                    <article className="new-gifts-page">
                        <Fade in={true} timeout={500}>
                            <Container fluid>
                                <ConnectionProfileContainer
                                    connection={connection}
                                    updateConnection={updateConnection}
                                    occasions={occasions}
                                    selectedOccasion={{ occasion: selectedOccasion, setOccasion: setSelectedOccasion }}
                                    editedOccasions={editedOccasions}
                                />
                            </Container>
                        </Fade>
                        <OccasionComponent
                            occasions={occasions}
                            connection={connection}
                            onEditedOccasions={(e) => onNewOccasions(e)}
                            onDeleteOccasion={(occ) => deleteOccasion(occ)}
                            onEditOccasionBudget={(occ, budget, date) => editOccasionBudget(occ, budget, date)}
                        />
                        {/* <InterestComponent
                            interests={interests}
                            connectionId={connection.connectionId}
                            isCurrentUser={false}
                            onEditedInterests={(e) => onNewInterests(e)}
                            onDeleteInterest={(int) => deleteInterest(int)}
                        /> */}
                    </article>
                </>}
        </>
    )
}

export default UserConnectionPage;
